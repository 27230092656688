import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

export interface ContainerProps {
  className?: string;
}

const Container: React.FC<ContainerProps> = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e1frm6kr0"
} : {
  target: "e1frm6kr0",
  label: "Container"
})(process.env.NODE_ENV === "production" ? {
  name: "1int4hs",
  styles: "width:90%;min-width:1210px;max-width:1400px;box-sizing:border-box;margin:0px auto;padding:0px 20px;@media screen and (max-width: 1280px){min-width:-webkit-fill-available;}"
} : {
  name: "1int4hs",
  styles: "width:90%;min-width:1210px;max-width:1400px;box-sizing:border-box;margin:0px auto;padding:0px 20px;@media screen and (max-width: 1280px){min-width:-webkit-fill-available;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjIvQ29udGFpbmVyL0NvbnRhaW5lci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBTXNEIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjIvQ29udGFpbmVyL0NvbnRhaW5lci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBzdHlsZWQgfSBmcm9tIFwidHdpbi5tYWNyb1wiXG5cbmV4cG9ydCBpbnRlcmZhY2UgQ29udGFpbmVyUHJvcHMge1xuICAgIGNsYXNzTmFtZT86IHN0cmluZ1xufVxuXG5jb25zdCBDb250YWluZXI6IFJlYWN0LkZDPENvbnRhaW5lclByb3BzPiA9IHN0eWxlZC5kaXZgXG4gICAgd2lkdGg6IDkwJTtcbiAgICBtaW4td2lkdGg6IDEyMTBweDtcbiAgICBtYXgtd2lkdGg6IDE0MDBweDtcblxuICAgIGJveC1zaXppbmc6IGJvcmRlci1ib3g7XG4gICAgbWFyZ2luOiAwcHggYXV0bztcbiAgICBwYWRkaW5nOiAwcHggMjBweDtcblxuICAgIEBtZWRpYSBzY3JlZW4gYW5kIChtYXgtd2lkdGg6IDEyODBweCkge1xuICAgICAgICBtaW4td2lkdGg6IC13ZWJraXQtZmlsbC1hdmFpbGFibGU7XG4gICAgfVxuYFxuXG5leHBvcnQgZGVmYXVsdCBDb250YWluZXJcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export default Container;