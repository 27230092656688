import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CoinTransactionType, FiatTransactionType, TransactionType, WalletHistoryModel, WalletTransactionStatus } from "@type/model/wallet-history";
import { fetchWalletHistory } from "./actions";

const getTransactionType = (transactionType: number, coinCode: string): TransactionType => {
  const isFiat = coinCode === "IDR";

  if (isFiat) {
    switch (transactionType) {
      case FiatTransactionType.Deposit:
        return TransactionType.Deposit;

      case FiatTransactionType.Withdraw:
        return TransactionType.Withdraw;

      default:
    }
  }

  switch (transactionType) {
    case CoinTransactionType.Deposit:
      return TransactionType.Deposit;

    case CoinTransactionType.Withdraw:
      return TransactionType.Withdraw;

    case CoinTransactionType.Distribution:
      return TransactionType.Distribution;

    default:
      return TransactionType.Deposit;
  }
};

const isEWalletTransaction = (tx: string) => Boolean(tx.includes("EWALLET"));

const getTransactionStatus = (coinCode: string, status: number, tx: string): WalletTransactionStatus => {
  if (coinCode === "IDR") {
    const isEWallet = isEWalletTransaction(tx);

    switch (status) {
      case 0:
        return WalletTransactionStatus.IN_PROCESS;

      case 1:
        return WalletTransactionStatus.SUCCESS;

      case 2:
        return WalletTransactionStatus.CANCELLED;

      case 3:
        return WalletTransactionStatus.FAILED;

      case 4:
        if (isEWallet) {
          return WalletTransactionStatus.EWALLET_PENDING;
        }

        return WalletTransactionStatus.BANK_PENDING;

      case 7:
        if (isEWallet) {
          return WalletTransactionStatus.WAITING_FOR_PAYMENT;
        }

        return WalletTransactionStatus.IN_PROCESS;

      case 9:
        return WalletTransactionStatus.EMAIL_CONFIRMATION;

      default:
    }
  }

  switch (status) {
    case 0:
      return WalletTransactionStatus.IN_PROCESS;

    case 1:
      return WalletTransactionStatus.SUCCESS;

    case 2:
      return WalletTransactionStatus.EMAIL_CONFIRMATION;

    case 3:
      return WalletTransactionStatus.CANCELLED;

    case 6:
      return WalletTransactionStatus.IN_PROCESS;

    case 8:
      return WalletTransactionStatus.IN_PROCESS;

    default:
  }

  return WalletTransactionStatus.IN_PROCESS;
};

const walletHistorySlicer = createSlice({
  name: "walletHistory",
  initialState: {
    walletHistory: ([] as WalletHistoryModel[] | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchWalletHistory.pending.type]: state => {
      state.isLoading = true;
      state.walletHistory = null;
    },
    [fetchWalletHistory.fulfilled.type]: (state, action: PayloadAction<WalletHistoryAPI>) => {
      state.walletHistory = action.payload.result.map(item => ({
        id: item.id,
        hash: item.hash,
        date: dayjs(item.dt).unix(),
        description: item.status,
        coinCode: item.c,
        transactionType: getTransactionType(Number(item.tt), item.c),
        status: getTransactionStatus(item.c, item.st, item.tx),
        chainName: item.chain_name,
        walletLabel: item.wallet_label,
        address: {
          from: item.fa,
          to: item.ta
        },
        amount: item.a,
        random: item.rnd,
        fee: {
          original_fee: new BigNumber(item.f).minus(item.vat).toNumber(),
          sms_fee: item.sf,
          vat: {
            idr: item.vatrp,
            coin: item.vat
          }
        },
        netAmount: new BigNumber(item.a).minus(item.f).minus(item.sf).toNumber(),
        bs: item.bs,
        tx: {
          hash: item.tx,
          url: item.txurl
        },
        note: item.nt,
        notes: item.notes,
        actions: item.actions
      }));
      state.isLoading = false;
    }
  }
});
export default walletHistorySlicer;