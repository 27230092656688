import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatAddress } from "@utils/address";
export interface FetchSendCoinMultipleChainFeeArgs {
  coinCode: string;
  networkId: string;
  address: string;
  tagUrl?: string;
  tagAddress?: string;
}
export const fetchSendCoinMultipleChainFee = createAsyncThunk<SendCoinMultipleChainFeeAPI, FetchSendCoinMultipleChainFeeArgs>("sendCoinFee/fetchSendCoinMultipleChainFee", async ({
  coinCode,
  networkId,
  address,
  tagAddress,
  tagUrl
}) => {
  const response = await baseFetchApi({
    url: `/wallet/${coinCode}/withdraw/${networkId}/fee`,
    method: "POST",
    data: {
      address: formatAddress({
        address,
        tagAddress,
        tagUrl
      })
    }
  });
  return response;
});
export interface FetchSendCoinFeeArgs {
  coinId: number;
  address: string;
  tagUrl?: string;
  tagAddress?: string;
}
export const fetchSendCoinFee = createAsyncThunk<SendCoinFeeAPI, FetchSendCoinFeeArgs>("sendCoinFee/fetchSendCoin", async ({
  coinId,
  address,
  tagUrl,
  tagAddress
}) => {
  const response = await baseFetchApi({
    url: `/coinfee`,
    method: "POST",
    data: {
      t: formatAddress({
        address,
        tagAddress,
        tagUrl
      }),
      c: coinId
    }
  });
  return response;
});