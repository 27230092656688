import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchCareers } from "./actions";
const careersSlicer = createSlice({
  name: "careers",
  initialState: {
    careers: (null as CareersAPI[] | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchCareers.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchCareers.fulfilled.type]: (state, action: PayloadAction<CareersAPI[]>) => {
      state.careers = action.payload;
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      careers: {
        careers: CareersAPI[];
      };
    }>) => {
      if (action.payload?.careers?.careers) {
        state.careers = action.payload.careers.careers;
      }
    }
  }
});
export default careersSlicer;