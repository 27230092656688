import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface DetailTradeArgs {
  did: string;
  wid: string;
  mi: string;
}
export const fetchDetailTrade = createAsyncThunk<boolean, DetailTradeArgs>("history/detailTrade", async ({
  did,
  wid,
  mi
}) => {
  const formData = new FormData();
  formData.append("did", did);
  formData.append("wid", wid);
  formData.append("mi", mi);
  const response = await baseFetchApi({
    url: "/detailtrade",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData
  });
  return response;
});