import { baseFetchApiV3 } from "@api/baseApi";
import { fetchFamilymemberData } from "@redux/family-member-data/actions";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchFamilyStatusInformation = createAsyncThunk("family/fetchFamilyStatusInformation", async () => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<FamilyStatusInformationAPI>>({
    url: "/user/family",
    method: "GET"
  });
  return response.data;
});
interface Success {
  message: string;
  data?: string | null;
}
export const generateFamilyCodeAction = createAsyncThunk<Success>("family/generateFamilyCode", async (_, {
  dispatch
}) => {
  const response = await baseFetchApiV3<Success>({
    url: "/user/family",
    method: "POST"
  });
  const familyStatusInfo = await dispatch(fetchFamilyStatusInformation()).unwrap();
  const familyCode = familyStatusInfo.owner.code;
  await dispatch(fetchFamilymemberData());
  const responseWithFamilyCode = { ...response,
    data: familyCode
  };
  return responseWithFamilyCode;
});
export const deactivateFamilyCodeAction = createAsyncThunk<Success>("family/deactivateFamilyCode", async (_, {
  dispatch
}) => {
  const response = await baseFetchApiV3<Success>({
    url: "/user/family",
    method: "DELETE"
  });
  dispatch(fetchFamilyStatusInformation());
  return response;
});