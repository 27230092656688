import _styled from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import IconComponents from "./images";

const StyledIcon = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e15lc3440"
} : {
  target: "e15lc3440",
  label: "StyledIcon"
})(process.env.NODE_ENV === "production" ? {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center"
} : {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjIvSWNvbnMvSWNvbnMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU82QiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL3YyL0ljb25zL0ljb25zLnRzeCIsInNvdXJjZXNDb250ZW50IjpbIi8qIGVzbGludC1kaXNhYmxlIHJlYWN0L2pzeC1wcm9wcy1uby1zcHJlYWRpbmcgKi9cbmltcG9ydCBSZWFjdCBmcm9tIFwicmVhY3RcIlxuXG5pbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIlxuXG5pbXBvcnQgSWNvbkNvbXBvbmVudHMgZnJvbSBcIi4vaW1hZ2VzXCJcblxuY29uc3QgU3R5bGVkSWNvbiA9IHN0eWxlZC5kaXZgXG4gICAgd2lkdGg6IGZpdC1jb250ZW50O1xuICAgIGhlaWdodDogZml0LWNvbnRlbnQ7XG5cbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuYFxuXG5leHBvcnQgdHlwZSBJY29uVHlwZSA9IGtleW9mIHR5cGVvZiBJY29uQ29tcG9uZW50c1xuXG5leHBvcnQgaW50ZXJmYWNlIEljb25zUHJvcHMgZXh0ZW5kcyBSZWFjdC5TVkdQcm9wczxTVkdTVkdFbGVtZW50PiB7XG4gICAgLyoqXG4gICAgICogSWNvbiBuYW1lXG4gICAgICovXG4gICAgaWNvbjoga2V5b2YgdHlwZW9mIEljb25Db21wb25lbnRzXG4gICAgLyoqXG4gICAgICogd3JhcHBlciBjbGFzc25hbWVcbiAgICAgKi9cbiAgICB3cmFwcGVyQ2xhc3NuYW1lPzogc3RyaW5nXG59XG5cbmNvbnN0IEljb25zOiBSZWFjdC5GQzxJY29uc1Byb3BzPiA9ICh7IGljb24sIHdyYXBwZXJDbGFzc25hbWUsIC4uLnByb3BzIH06IEljb25zUHJvcHMpID0+IHtcbiAgICBjb25zdCBDb21wb25lbnQgPSBJY29uQ29tcG9uZW50c1tpY29uXVxuXG4gICAgcmV0dXJuIChcbiAgICAgICAgPFN0eWxlZEljb24gY2xhc3NOYW1lPXt3cmFwcGVyQ2xhc3NuYW1lfT5cbiAgICAgICAgICAgIDxDb21wb25lbnQgey4uLnByb3BzfSAvPlxuICAgICAgICA8L1N0eWxlZEljb24+XG4gICAgKVxufVxuXG5JY29ucy5kZWZhdWx0UHJvcHMgPSB7XG4gICAgd2lkdGg6IDE2LFxuICAgIGhlaWdodDogMTYsXG4gICAgd3JhcHBlckNsYXNzbmFtZTogdW5kZWZpbmVkXG59XG5cbmV4cG9ydCBkZWZhdWx0IEljb25zXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export type IconType = keyof typeof IconComponents;
export interface IconsProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Icon name
   */
  icon: keyof typeof IconComponents;
  /**
   * wrapper classname
   */

  wrapperClassname?: string;
}

const Icons: React.FC<IconsProps> = ({
  icon,
  wrapperClassname,
  ...props
}: IconsProps) => {
  const Component = IconComponents[icon];
  return <StyledIcon className={wrapperClassname}>
            <Component {...props} />
        </StyledIcon>;
};

Icons.defaultProps = {
  width: 16,
  height: 16,
  wrapperClassname: undefined
};
export default Icons;