import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { checkResetPasswordHash, resetPassword } from "./actions";
const resetPasswordSlicer = createSlice({
  name: "resetPassword",
  initialState: {
    checkHash: (null as CheckResetPasswordHashAPI | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [checkResetPasswordHash.pending.type]: state => {
      state.isLoading = true;
    },
    [checkResetPasswordHash.fulfilled.type]: (state, action: PayloadAction<CheckResetPasswordHashAPI>) => {
      state.checkHash = action.payload;
      state.isLoading = false;
    },
    [checkResetPasswordHash.rejected.type]: state => {
      state.checkHash = null;
      state.isLoading = false;
    },
    [resetPassword.pending.type]: state => {
      state.isLoading = true;
    },
    [resetPassword.fulfilled.type]: state => {
      state.checkHash = null;
      state.isLoading = false;
    },
    [resetPassword.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default resetPasswordSlicer;