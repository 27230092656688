import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const advanceTradeFormSlicer = createSlice({
  name: "advanceTradeForm",
  initialState: {
    price: (null as number | null)
  },
  reducers: {
    updatePrice: (state, action: PayloadAction<number | null>) => {
      state.price = action.payload;
    }
  }
});
export const {
  updatePrice
} = advanceTradeFormSlicer.actions;
export default advanceTradeFormSlicer;