import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchReceiveCoin, FetchReceiveCoinResponse } from "./actions";
const receiveCoinSlicer = createSlice({
  name: "receiveCoin",
  initialState: {
    receiveCoin: (null as ReceiveCoinAPI | null),
    coinCode: (null as string | null),
    networkId: (null as string | null),
    isLoading: true,
    error: (null as string | null)
  },
  reducers: {},
  extraReducers: {
    [fetchReceiveCoin.pending.type]: state => {
      state.isLoading = true;
      state.receiveCoin = null;
      state.coinCode = null;
      state.networkId = null;
      state.error = null;
    },
    [fetchReceiveCoin.fulfilled.type]: (state, action: PayloadAction<FetchReceiveCoinResponse>) => {
      state.receiveCoin = action.payload.data;
      state.coinCode = action.payload.coinCode;
      state.networkId = action.payload.networkId;
      state.isLoading = false;
    },
    [fetchReceiveCoin.rejected.type]: (state, action) => {
      state.error = action.error?.message;
      state.isLoading = false;
    }
  }
});
export default receiveCoinSlicer;