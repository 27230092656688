import fetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface OTCSettingsArgs {
  id: number;
}
export const fetchOTCSettings = createAsyncThunk<OTCSettingsAPI, OTCSettingsArgs>("OTCSettings/fetchOTCSettings", async ({
  id
}) => {
  const response = await fetchApi({
    url: `/otc/product/${id}/settings`,
    method: "GET"
  });
  return response;
});