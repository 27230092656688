import { createSlice } from "@reduxjs/toolkit";
import { internalTransferConfirm, sendCoinConfirm } from "./actions";
const emailConfirmationSlice = createSlice({
  name: "emailConfirmation",
  initialState: {
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [internalTransferConfirm.pending.type]: state => {
      state.isLoading = true;
    },
    [internalTransferConfirm.fulfilled.type]: state => {
      state.isLoading = false;
    },
    [internalTransferConfirm.rejected.type]: state => {
      state.isLoading = false;
    },
    [sendCoinConfirm.pending.type]: state => {
      state.isLoading = true;
    },
    [sendCoinConfirm.fulfilled.type]: state => {
      state.isLoading = false;
    },
    [sendCoinConfirm.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default emailConfirmationSlice;