import { getAuth } from "@helpers/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchGenerateTwoFa } from "./action";
const auth = getAuth();
const generateTwoFaSlicer = createSlice({
  name: "generateTwoFa",
  initialState: (({
    token: auth.token || null,
    otp: null
  } as unknown) as GenerateTwoFa),
  reducers: {},
  extraReducers: {
    [fetchGenerateTwoFa.pending.type]: state => {
      state.token = undefined;
      state.otp = undefined;
    },
    [fetchGenerateTwoFa.fulfilled.type]: (state, action: PayloadAction<GenerateTwoFa>) => {
      state.token = action.payload?.token;
      state.otp = action.payload?.otp;
    }
  }
});
export default generateTwoFaSlicer;