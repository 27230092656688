import baseFetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface CancelSendArgs {
  token?: string;
  hash?: string;
}
export const fetchCancelCoin = createAsyncThunk<string, CancelSendArgs>("history/cancelSendCoin", async ({
  hash
}) => {
  const auth = getAuth();
  const formData = new FormData();
  const {
    token
  } = auth;
  formData.append("token", token || "");
  const response = await baseFetchApi({
    url: `/wallet/cancel/coin/${hash}`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData
  });
  return response;
});