import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import classNames from "classnames";
import { Case, Default, Switch } from "@components/If";

const DividerStrong = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "ey70ve01"
} : {
  target: "ey70ve01",
  label: "DividerStrong"
})(process.env.NODE_ENV === "production" ? {
  name: "1me6za5",
  styles: "width:100%;border-top-width:1px;--tw-border-opacity:1;border-top-color:rgba(var(--colors-grey-200), var(--tw-border-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-top-color:rgba(var(--colors-dark-grey-300), var(--tw-border-opacity));}"
} : {
  name: "1me6za5",
  styles: "width:100%;border-top-width:1px;--tw-border-opacity:1;border-top-color:rgba(var(--colors-grey-200), var(--tw-border-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-top-color:rgba(var(--colors-dark-grey-300), var(--tw-border-opacity));}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjIvRGl2aWRlci9EaXZpZGVyLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLZ0MiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy92Mi9EaXZpZGVyL0RpdmlkZXIudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IGNsYXNzTmFtZXMgZnJvbSBcImNsYXNzbmFtZXNcIlxuaW1wb3J0IHR3LCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IHsgQ2FzZSwgRGVmYXVsdCwgU3dpdGNoIH0gZnJvbSBcIkBjb21wb25lbnRzL0lmXCJcblxuY29uc3QgRGl2aWRlclN0cm9uZyA9IHN0eWxlZC5kaXZgXG4gICAgJHt0d2B3LWZ1bGwgYm9yZGVyLXQgYm9yZGVyLXQtZ3JleS0yMDAgZGFyazpib3JkZXItdC1kYXJrLWdyZXktMzAwYH1cbmBcbmNvbnN0IERpdmlkZXJTdWJ0bGUgPSBzdHlsZWQuZGl2YFxuICAgICR7dHdgdy1mdWxsIGJvcmRlci10IGJvcmRlci10LWdyZXktMTAwIGRhcms6Ym9yZGVyLXQtZGFyay1ncmV5LTEwMGB9XG5gXG5cbmV4cG9ydCBpbnRlcmZhY2UgRGl2aWRlclByb3BzIGV4dGVuZHMgT21pdDxSZWFjdC5IVE1MUHJvcHM8SFRNTEhSRWxlbWVudD4sIFwiYXNcIj4ge1xuICAgIC8qKlxuICAgICAqIFNldCB2YXJpYW50IGRpdmlkZXJcbiAgICAgKiBAZGVmYXVsdCBcInN1YnRsZVwiXG4gICAgICovXG4gICAgdmFyaWFudD86IFwic3VidGxlXCIgfCBcInN0cm9uZ1wiXG4gICAgLyoqXG4gICAgICogY2xhc3NuYW1lIG9mIGRpdmlkZXJcbiAgICAgKi9cbiAgICBjbGFzc05hbWU/OiBzdHJpbmdcbn1cblxuY29uc3QgRGl2aWRlcjogUmVhY3QuRkM8RGl2aWRlclByb3BzPiA9ICh7IHZhcmlhbnQsIGNsYXNzTmFtZSB9KSA9PiAoXG4gICAgPFN3aXRjaD5cbiAgICAgICAgPENhc2UgY29uZGl0aW9uPXt2YXJpYW50ID09PSBcInN0cm9uZ1wifT5cbiAgICAgICAgICAgIDxEaXZpZGVyU3Ryb25nIGNsYXNzTmFtZT17Y2xhc3NOYW1lcyhjbGFzc05hbWUpfSAvPlxuICAgICAgICA8L0Nhc2U+XG4gICAgICAgIDxEZWZhdWx0PlxuICAgICAgICAgICAgPERpdmlkZXJTdWJ0bGUgY2xhc3NOYW1lPXtjbGFzc05hbWVzKGNsYXNzTmFtZSl9IC8+XG4gICAgICAgIDwvRGVmYXVsdD5cbiAgICA8L1N3aXRjaD5cbilcblxuRGl2aWRlci5kZWZhdWx0UHJvcHMgPSB7XG4gICAgdmFyaWFudDogXCJzdWJ0bGVcIixcbiAgICBjbGFzc05hbWU6IHVuZGVmaW5lZFxufVxuXG5leHBvcnQgZGVmYXVsdCBEaXZpZGVyXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

const DividerSubtle = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "ey70ve00"
} : {
  target: "ey70ve00",
  label: "DividerSubtle"
})(process.env.NODE_ENV === "production" ? {
  name: "h2flv3",
  styles: "width:100%;border-top-width:1px;--tw-border-opacity:1;border-top-color:rgba(var(--colors-grey-100), var(--tw-border-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-top-color:rgba(var(--colors-dark-grey-100), var(--tw-border-opacity));}"
} : {
  name: "h2flv3",
  styles: "width:100%;border-top-width:1px;--tw-border-opacity:1;border-top-color:rgba(var(--colors-grey-100), var(--tw-border-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-top-color:rgba(var(--colors-dark-grey-100), var(--tw-border-opacity));}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjIvRGl2aWRlci9EaXZpZGVyLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFRZ0MiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy92Mi9EaXZpZGVyL0RpdmlkZXIudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IGNsYXNzTmFtZXMgZnJvbSBcImNsYXNzbmFtZXNcIlxuaW1wb3J0IHR3LCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IHsgQ2FzZSwgRGVmYXVsdCwgU3dpdGNoIH0gZnJvbSBcIkBjb21wb25lbnRzL0lmXCJcblxuY29uc3QgRGl2aWRlclN0cm9uZyA9IHN0eWxlZC5kaXZgXG4gICAgJHt0d2B3LWZ1bGwgYm9yZGVyLXQgYm9yZGVyLXQtZ3JleS0yMDAgZGFyazpib3JkZXItdC1kYXJrLWdyZXktMzAwYH1cbmBcbmNvbnN0IERpdmlkZXJTdWJ0bGUgPSBzdHlsZWQuZGl2YFxuICAgICR7dHdgdy1mdWxsIGJvcmRlci10IGJvcmRlci10LWdyZXktMTAwIGRhcms6Ym9yZGVyLXQtZGFyay1ncmV5LTEwMGB9XG5gXG5cbmV4cG9ydCBpbnRlcmZhY2UgRGl2aWRlclByb3BzIGV4dGVuZHMgT21pdDxSZWFjdC5IVE1MUHJvcHM8SFRNTEhSRWxlbWVudD4sIFwiYXNcIj4ge1xuICAgIC8qKlxuICAgICAqIFNldCB2YXJpYW50IGRpdmlkZXJcbiAgICAgKiBAZGVmYXVsdCBcInN1YnRsZVwiXG4gICAgICovXG4gICAgdmFyaWFudD86IFwic3VidGxlXCIgfCBcInN0cm9uZ1wiXG4gICAgLyoqXG4gICAgICogY2xhc3NuYW1lIG9mIGRpdmlkZXJcbiAgICAgKi9cbiAgICBjbGFzc05hbWU/OiBzdHJpbmdcbn1cblxuY29uc3QgRGl2aWRlcjogUmVhY3QuRkM8RGl2aWRlclByb3BzPiA9ICh7IHZhcmlhbnQsIGNsYXNzTmFtZSB9KSA9PiAoXG4gICAgPFN3aXRjaD5cbiAgICAgICAgPENhc2UgY29uZGl0aW9uPXt2YXJpYW50ID09PSBcInN0cm9uZ1wifT5cbiAgICAgICAgICAgIDxEaXZpZGVyU3Ryb25nIGNsYXNzTmFtZT17Y2xhc3NOYW1lcyhjbGFzc05hbWUpfSAvPlxuICAgICAgICA8L0Nhc2U+XG4gICAgICAgIDxEZWZhdWx0PlxuICAgICAgICAgICAgPERpdmlkZXJTdWJ0bGUgY2xhc3NOYW1lPXtjbGFzc05hbWVzKGNsYXNzTmFtZSl9IC8+XG4gICAgICAgIDwvRGVmYXVsdD5cbiAgICA8L1N3aXRjaD5cbilcblxuRGl2aWRlci5kZWZhdWx0UHJvcHMgPSB7XG4gICAgdmFyaWFudDogXCJzdWJ0bGVcIixcbiAgICBjbGFzc05hbWU6IHVuZGVmaW5lZFxufVxuXG5leHBvcnQgZGVmYXVsdCBEaXZpZGVyXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export interface DividerProps extends Omit<React.HTMLProps<HTMLHRElement>, "as"> {
  /**
   * Set variant divider
   * @default "subtle"
   */
  variant?: "subtle" | "strong";
  /**
   * classname of divider
   */

  className?: string;
}

const Divider: React.FC<DividerProps> = ({
  variant,
  className
}) => <Switch>
        <Case condition={variant === "strong"}>
            <DividerStrong className={classNames(className)} />
        </Case>
        <Default>
            <DividerSubtle className={classNames(className)} />
        </Default>
    </Switch>;

Divider.defaultProps = {
  variant: "subtle",
  className: undefined
};
export default Divider;