/* eslint-disable import/prefer-default-export */
import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface Filter {
  offset?: number;
  start_date?: string;
  end_date?: string;
  trans_type?: number;
  status?: number;
}
export interface ModalArgs {
  id: string;
  date: number;
  initialDate: number;
  transType: number;
  paymentType: number;
  paymentBranch: string;
  paymentName: string;
  paymentAccount: string;
  amount: number;
  randomNumber: number;
  fee: number;
  vat: number;
  smsFee: number;
  status: number;
  receipt: boolean;
  hash: string;
  notes: string;
  ewallet_data?: HistoryIdrEwalletDataApi | null;
  paymentLogo: string;
  fromAddress: string;
  toAddress: string;
  paymentBankRecommendationsId?: number | null;
  metaData?: MetaDataAPI;
}
export interface FetchHistoryTransIdrArgs extends Filter {
  hash?: string;
}
export const handleFetchHistoryTransIdr = async ({
  offset,
  start_date,
  end_date,
  trans_type,
  status,
  hash
}: FetchHistoryTransIdrArgs) => {
  let params: object = {
    offset,
    start_date,
    end_date
  };

  if (status) {
    params = { ...params,
      status
    };
  }

  if (trans_type) {
    params = { ...params,
      trans_type
    };
  }

  const response = await baseFetchApi<HistoryIdrApi[]>({
    url: hash ? `/wallet/history/family/${hash}/idr` : "/wallet/history/idr",
    method: "GET",
    params
  });
  return response;
};
export const fetchHistoryTransIdr = createAsyncThunk<HistoryIdrApi[], FetchHistoryTransIdrArgs>("history/fetchHistoryIdr", handleFetchHistoryTransIdr);
export const fetchHistoryTransIdrPagination = createAsyncThunk<HistoryIdrApi[], FetchHistoryTransIdrArgs>("history/fetchHistoryTransIdrPagination", async ({
  offset,
  end_date,
  start_date,
  status,
  trans_type,
  hash
}) => {
  let params: object = {
    offset,
    start_date,
    end_date
  };

  if (status) {
    params = { ...params,
      status
    };
  }

  if (trans_type) {
    params = { ...params,
      trans_type
    };
  }

  const response = await baseFetchApi({
    url: hash ? `/wallet/history/family/${hash}/idr` : "/wallet/history/idr",
    method: "GET",
    params
  });
  return response;
});