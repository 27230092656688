import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface LangArgs {
  lang: "en" | "id";
}
interface Success {
  success: number;
}
export const updateLang = createAsyncThunk<Success, LangArgs>("lang/updateLang", async ({
  lang
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<Success>>({
    url: `/user/lang`,
    method: "PUT",
    data: {
      lang
    }
  });
  return response.result;
});