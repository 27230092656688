/* eslint-disable no-restricted-globals */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFamilyStatusInformation } from "./actions";
const familyStatusInformationSlice = createSlice({
  name: "familyStatusInformation",
  initialState: {
    familyStatusInformation: (null as FamilyStatusInformationModel | null),
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchFamilyStatusInformation.pending.type]: state => {
      state.isLoading = true;
      state.familyStatusInformation = null;
    },
    [fetchFamilyStatusInformation.fulfilled.type]: (state, action: PayloadAction<FamilyStatusInformationAPI>) => {
      state.familyStatusInformation = action.payload;
      state.isLoading = false;
    },
    [fetchFamilyStatusInformation.rejected.type]: state => {
      state.isLoading = false;
      state.familyStatusInformation = null;
    }
  }
});
export default familyStatusInformationSlice;