import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSendCoinFee, fetchSendCoinMultipleChainFee } from "./actions";
const sendCoinFeeSlicer = createSlice({
  name: "sendCoinFee",
  initialState: {
    sendCoinFee: (null as SendCoinMultipleChainFeeModel | null),
    isLoading: false
  },
  reducers: {
    clearSendCoinFeeData: state => {
      state.sendCoinFee = null;
      state.isLoading = false;
    }
  },
  extraReducers: {
    [fetchSendCoinMultipleChainFee.pending.type]: state => {
      state.sendCoinFee = null;
      state.isLoading = true;
    },
    [fetchSendCoinMultipleChainFee.fulfilled.type]: (state, action: PayloadAction<SendCoinMultipleChainFeeAPI>) => {
      if (action.payload.account_id) {
        state.sendCoinFee = {
          coinId: action.payload.account_id,
          coinName: action.payload.account_name,
          coinCode: action.payload.account_code,
          chainName: action.payload.chain,
          withdrawFee: Number(action.payload.withdraw_fee),
          isInternal: action.payload.is_internal
        };
      }

      state.isLoading = false;
    },
    [fetchSendCoinMultipleChainFee.rejected.type]: state => {
      state.sendCoinFee = null;
      state.isLoading = false;
    },
    [fetchSendCoinFee.pending.type]: state => {
      state.sendCoinFee = null;
      state.isLoading = true;
    },
    [fetchSendCoinFee.fulfilled.type]: (state, action: PayloadAction<SendCoinFeeAPI>) => {
      state.sendCoinFee = {
        withdrawFee: action.payload.fee,
        isInternal: Boolean(action.payload.is_internal)
      };
      state.isLoading = false;
    },
    [fetchSendCoinFee.rejected.type]: state => {
      state.sendCoinFee = null;
      state.isLoading = false;
    }
  }
});
export const {
  clearSendCoinFeeData
} = sendCoinFeeSlicer.actions;
export default sendCoinFeeSlicer;