import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBuySellVolume } from "./actions";
const buySellVolumeSlicer = createSlice({
  name: "buySellVolume",
  initialState: {
    buySellVolume: (null as BuySellVolumeModel | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchBuySellVolume.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBuySellVolume.fulfilled.type]: (state, action: PayloadAction<BuySellVolumeAPI>) => {
      state.isLoading = false;
      state.buySellVolume = {
        buyInPercentage: action.payload.result.buy_in_percentage,
        sellInPercentage: action.payload.result.sell_in_percentage,
        expiredAt: action.payload.result.expired_at
      };
    }
  }
});
export default buySellVolumeSlicer;