import React from "react";
import ReactSkeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useTheme } from "@emotion/react";
import "react-loading-skeleton/dist/skeleton.css";
interface SkeletonProps {
  count?: number;
  width: number | string;
  height?: number;
  circle?: boolean;
  className?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  count,
  width,
  height,
  circle,
  className
}: SkeletonProps) => {
  const theme = useTheme();
  return <SkeletonTheme baseColor={theme.components.skeleton.baseColor} highlightColor={theme.components.skeleton.highlightColor}>
            <ReactSkeleton style={{
      zIndex: "unset"
    }} count={count} width={width} height={height} circle={circle} className={className} containerTestId='skeleton' />
        </SkeletonTheme>;
};

Skeleton.defaultProps = {
  count: 1,
  height: undefined,
  circle: false,
  className: undefined
};
export default Skeleton;