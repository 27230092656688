import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface SendSMSArgs {
  phoneNumber?: string;
  hash?: string;
  token?: string;
} // eslint-disable-next-line @typescript-eslint/default-param-last

export const sendSms = createAsyncThunk<SendSMSAPI, SendSMSArgs>("sendSms/sendSms", async ({
  phoneNumber,
  hash
}, {
  getState
}) => {
  const state: any = getState();
  const {
    hash: authHash,
    token,
    isLoggedIn
  } = (state.auth as AuthModel);
  let data: object = {};

  if (phoneNumber) {
    data = { ...data,
      phone_number: phoneNumber
    };
  }

  if (!isLoggedIn && authHash) {
    data = { ...data,
      hash: authHash
    };
  }

  if (hash) {
    data = { ...data,
      hash
    };
  }

  if (token) {
    data = { ...data,
      token
    };
  }

  const response = await baseFetchApiV3<SendSMSAPI>({
    url: "/user/otp/sms",
    method: "POST",
    data
  });
  return response;
});