import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchDepthChart } from "./actions";
const depthChartSlicer = createSlice({
  name: "depthChart",
  initialState: {
    depthChart: (null as DepthChartModel[] | null),
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchDepthChart.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchDepthChart.fulfilled.type]: (state, action: PayloadAction<DepthChartAPI>) => {
      const convertData = (list: DepthChartSingleData[], type: "buy" | "sell") => {
        let totalVolume = 0;
        let newData = [];

        for (let i = 0; i < list.length; i++) {
          const data = list[i];
          const price = Number(data[1]);
          const volume = data[2];
          totalVolume += volume;
          newData.push({
            price,
            volume,
            totalVolume,
            type
          });
        }

        newData = newData.sort((a, b) => a.price - b.price);
        return newData;
      };

      const buyData = convertData(action.payload.b, "buy");
      const sellData = convertData(action.payload.s, "sell");
      state.depthChart = buyData.concat(sellData);
      state.isLoading = false;
    }
  }
});
export default depthChartSlicer;