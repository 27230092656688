import fetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface CoinInfoArgs {
  code: string;
}
export const fetchCoinInfo = createAsyncThunk<CoinInfoAPI, CoinInfoArgs>("coinInfo/fetchCoinInfo", async ({
  code
}) => {
  const response = await fetchApi({
    url: `/coin-info/${code}`,
    method: "GET"
  });
  return response;
});