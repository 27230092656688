import dynamic from "next/dynamic";
const ContextIllustration = {
  Coupon: dynamic(() => import("./Coupon")),
  Delete: dynamic(() => import("./Delete")),
  Fingertip: dynamic(() => import("./Fingertip")),
  List: dynamic(() => import("./List")),
  Mail: dynamic(() => import("./Mail")),
  NoInternet: dynamic(() => import("./NoInternet")),
  Notification: dynamic(() => import("./Notification")),
  Referral: dynamic(() => import("./Referral")),
  Security: dynamic(() => import("./Security")),
  Staking1: dynamic(() => import("./Staking1")),
  Staking2: dynamic(() => import("./Staking2")),
  Staking3: dynamic(() => import("./Staking3")),
  StartInvesting: dynamic(() => import("./StartInvesting")),
  Verification: dynamic(() => import("./Verification"))
};
export default ContextIllustration;