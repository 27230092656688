import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchReferralDownline, fetchReferralDownlinePagination, ReferralDownlineResponse } from "./actions";
const referralDownlineSlicer = createSlice({
  name: "referralDownline",
  initialState: {
    referralDownline: (null as ReferralDownlineModel[] | null),
    total: 0,
    limit: 0,
    page: 0,
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchReferralDownline.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchReferralDownline.fulfilled.type]: (state, action: PayloadAction<ReferralDownlineResponse>) => {
      if (state.page === action.payload.page) {
        state.isLoading = false;
        return;
      }

      state.referralDownline = action.payload.members.map(downline => {
        const totalCommission = Number(downline.transaction) + Number(downline.withdraw) || 0;
        return {
          id: downline.id,
          name: downline.name,
          totalTransaction: Number(downline.transaction),
          totalDownline: Number(downline.total),
          totalWithdraw: Number(downline.withdraw),
          commission: totalCommission,
          verified: downline.status === 1
        };
      });
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.isLoading = false;
    },
    [fetchReferralDownlinePagination.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchReferralDownlinePagination.fulfilled.type]: (state, action: PayloadAction<ReferralDownlineResponse>) => {
      state.referralDownline?.push(...action.payload.members.map(downline => {
        const totalCommission = Number(downline.transaction) + Number(downline.withdraw) || 0;
        return {
          id: downline.id,
          name: downline.name,
          totalTransaction: Number(downline.transaction),
          totalDownline: Number(downline.total),
          totalWithdraw: Number(downline.withdraw),
          commission: totalCommission,
          verified: downline.status === 1
        };
      }));
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.page = action.payload.page;
      state.isLoading = false;
    }
  }
});
export default referralDownlineSlicer;