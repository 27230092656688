import fetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface TotalNotifications {
  result: number;
}
export const offset = 25;
export const fetchNotifications = createAsyncThunk<NotificationAPI[]>("notifications/fetchNotifications", async () => {
  const auth = getAuth();
  const res = await fetchApi({
    url: "/notifications",
    method: "POST",
    data: {
      token: auth.token
    }
  });
  return res;
});
export const fetchNotificationsPagination = createAsyncThunk<NotificationAPI[], number>("notifications/fetchNotificationsPagination", async (page: number) => {
  const auth = getAuth();
  const res = await fetchApi({
    url: "/notifications",
    method: "POST",
    data: {
      o: offset * page,
      token: auth.token
    }
  });
  return res;
});
export const fetchTotalNotifications = createAsyncThunk<TotalNotifications>("notifications/fetchTotalNotifications", async () => {
  const total = await fetchApi({
    url: "/totalnotifications",
    method: "GET"
  });
  return total;
});