import { getCookie } from "@helpers/cookies";
import { isBrowser } from "@utils/browser";
import isDevelopment from "@utils/isDevelopment";
import { MOENGAGE_APP_ID, MOENGAGE_DEBUG } from "./config";

const getDebugMode = () => {
  if (MOENGAGE_DEBUG) {
    return MOENGAGE_DEBUG === "1";
  }

  if (isDevelopment) {
    return true;
  }

  return false;
};

const getAppId = () => {
  const debugMode = getDebugMode();
  return `${MOENGAGE_APP_ID}${debugMode ? "_DEBUG" : ""}`;
};

export const getDeviceId = (): string | undefined => {
  try {
    if (!isBrowser) return undefined;
    if (!window?.Moengage) return undefined;
    const cookieDeviceId = getCookie("moe_uuid");

    if (cookieDeviceId) {
      return cookieDeviceId;
    }

    return window?.Moengage?.device?.getUuid?.();
  } catch (err) {
    return undefined;
  }
};
const moengageConfig = {
  appId: getAppId(),
  debugMode: getDebugMode()
};
export default moengageConfig;