import { createSlice } from "@reduxjs/toolkit";
import { sendCoin, sendCoinMultipleChain } from "./actions";
const sendCoinSlicer = createSlice({
  name: "sendCoin",
  initialState: {
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [sendCoinMultipleChain.pending.type]: state => {
      state.isLoading = true;
    },
    [sendCoinMultipleChain.fulfilled.type]: state => {
      state.isLoading = false;
    },
    [sendCoinMultipleChain.rejected.type]: state => {
      state.isLoading = false;
    },
    [sendCoin.pending.type]: state => {
      state.isLoading = true;
    },
    [sendCoin.fulfilled.type]: state => {
      state.isLoading = false;
    },
    [sendCoin.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default sendCoinSlicer;