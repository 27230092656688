import fetchApi, { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchLightningCoinFees = createAsyncThunk<LightningCoinFeeModel[]>("lightningCoinFees/fetchLightningCoinFees", async () => {
  const [markets, otcSettings] = await Promise.all([baseFetchApiV3<MarketApi[]>({
    url: "/market",
    method: "GET",
    params: {
      mode: "otc"
    }
  }), fetchApi<OTCSettingsAPI[]>({
    url: `/otc/products/settings`,
    method: "GET"
  })]);
  return markets.filter(market => market.is_lite).map(market => {
    const maxOrder = otcSettings.find(setting => setting.product_id === market.id)?.maximum_order;
    return {
      code: market.cd,
      name: market.n,
      logo: market.logo,
      max_order: maxOrder ? maxOrder / market.c : 0,
      max_order_idr: maxOrder || 0
    };
  });
});