/* eslint-disable import/prefer-default-export */
import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchApiKeys = createAsyncThunk<APIKeyAPI>("apiKeys/fetchApiKeys", async () => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<APIKeyAPI>>({
    url: "/api-key",
    method: "GET"
  });
  return response.data;
});
export interface AddApiKeyArgs {
  label: string;
  otp: string;
}
export const addApiKeyAction = createAsyncThunk<BaseResponseAPIV3, AddApiKeyArgs>("apiKeys/addApiKey", async ({
  label,
  otp
}, {
  dispatch
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3>({
    url: "/api-key",
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    data: {
      label,
      otp_code: otp
    }
  });
  dispatch(fetchApiKeys());
  return response;
});
export const deleteApiKeyAction = createAsyncThunk<BaseResponseAPIV3, string>("apiKeys/deleteApiKey", async (id, {
  dispatch
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3>({
    url: `/api-key/${id}`,
    method: "DELETE"
  });
  dispatch(fetchApiKeys());
  return response;
});