/* eslint-disable no-param-reassign */
import { HYDRATE } from "next-redux-wrapper";
import BigNumber from "bignumber.js";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getOldIDRDecimal, multipleDigits } from "@utils/digits";
import { fetchCoins } from "./actions";
const coinsSlicer = createSlice({
  name: "coins",
  initialState: {
    coins: (null as CoinsModel | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchCoins.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchCoins.fulfilled.type]: (state, action: PayloadAction<CoinsApi>) => {
      state.coins = action.payload.result.map(coin => ({
        id: coin.id,
        code: coin.accountcode,
        name: coin.accountname,
        validation: {
          send: {
            min: coin.minsend,
            max: coin.maxsend
          }
        },
        fee: {
          send: {
            internal: coin.sendfee_internal,
            external: new BigNumber(coin.sendfee_external).decimalPlaces(8, BigNumber.ROUND_UP).toNumber()
          },
          sms: coin.smsfee,
          takerfee: coin.takerfee
        },
        logo: coin.logo,
        digits: coin.digits,
        priceMovement: multipleDigits(coin.digits),
        priceDecimal: getOldIDRDecimal(coin.digits),
        decimals: coin.decimals,
        status: {
          enableDeposit: Boolean(coin.enablecreatewallet),
          enableWithdraw: Boolean(coin.enablesendfund),
          enableBuy: Boolean(coin.enablebuy),
          enableSell: Boolean(coin.enablesell)
        },
        tag: {
          name: coin.tag,
          url: coin.tagurl
        },
        notes: coin.notes
      }));
      state.isLoading = false;
    },
    [HYDRATE]: (state, action: PayloadAction<{
      coins: {
        coins: CoinsModel;
      };
    }>) => {
      if (action.payload?.coins?.coins) {
        state.coins = action.payload.coins.coins;
      }
    }
  }
});
export default coinsSlicer;