import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBankTransfer } from "./actions";
const bankTransfersSlicer = createSlice({
  name: "bankTransfers",
  initialState: {
    bankTransfers: (null as BankTransferModel[] | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchBankTransfer.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBankTransfer.fulfilled.type]: (state, action: PayloadAction<BankTransferAPI[]>) => {
      state.bankTransfers = action.payload.map(banktransfer => ({
        id: banktransfer.id,
        bank: banktransfer.bank,
        logo: banktransfer.logo
      }));
      state.isLoading = false;
    }
  }
});
export default bankTransfersSlicer;