import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface Args {
  id: string;
  walletId: number;
  migaId: number;
}
export const fetchOrderDetail = createAsyncThunk<OrderDetailAPI[], Args>("orderDetail/fetchOrderDetail", async ({
  id,
  walletId,
  migaId
}) => {
  const response = await baseFetchApi({
    url: "/detailtrade",
    method: "POST",
    data: {
      did: id,
      wid: walletId,
      mi: migaId
    }
  });
  return response;
});