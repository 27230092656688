import fetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface WalletBookArgs {
  coinId: number;
}
export const fetchWalletBooks = createAsyncThunk<WalletBookAPI[], WalletBookArgs>("walletBook/fetchWalletBooks", async ({
  coinId
}) => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/walletbook",
    method: "POST",
    data: {
      coin: coinId,
      token: auth.token
    }
  });
  return response;
});
export const addWalletBook = createAsyncThunk<SuccessV2API, AddWalletBookPayload>("walletBook/addWalletBook", async (payload, {
  dispatch
}) => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/savewallet",
    method: "POST",
    data: {
      coin: payload.coin,
      addr: payload.addr,
      walletname: payload.walletname,
      token: auth.token
    }
  });
  dispatch(fetchWalletBooks({
    coinId: payload.coin
  }));
  return response;
});