import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchUserSettingsAction = createAsyncThunk<UserSettingsAPI, string>("user/fetchUserSettings", async key => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<UserSettingsAPI>>({
    url: `/user/settings/${key}`,
    method: "GET"
  });
  return response.data;
});
export const addUserSettingsAction = createAsyncThunk("user/addUserSettings", async (payload: UserSettingsPayload, {
  dispatch
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<UserSettingsAPI>>({
    url: "/user/settings",
    method: "POST",
    data: payload,
    headers: {
      "Content-Type": "application/json"
    }
  });
  dispatch(fetchUserSettingsAction(payload.key));
  return response;
});
export const deleteUserSettingsAction = createAsyncThunk("user/deleteUserSettings", async (key: string) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3>({
    url: `/user/settings/${key}`,
    method: "DELETE"
  });
  return response;
});