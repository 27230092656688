/* eslint-disable simple-import-sort/imports */
import { createAsyncThunk } from "@reduxjs/toolkit";
import baseFetchApi from "@api/baseApi";
import { logout } from "@redux/auth/action";
export interface SendLostAuthArgs {
  otp: string;
  phoneNumber: string;
}
export const sendLostAuth = createAsyncThunk<SuccessAPI, SendLostAuthArgs>("lostAuth/sendLostAuth", async ({
  otp,
  phoneNumber
}, {
  getState,
  dispatch
}) => {
  const {
    auth
  } = (getState() as any);
  const response = await baseFetchApi<SuccessAPI>({
    url: "/lostauth",
    method: "POST",
    data: {
      hash: auth.hash,
      smstoken: otp,
      nohp: phoneNumber,
      jenis: 1
    }
  });
  dispatch(logout(true));
  return response;
});