import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { useState } from "react";
import { useRouter } from "next/router";
import { LanguageSwitch as LanguageSwitchComponent } from "@components/v3/Switch";
import useLang from "@hooks/useLang";

const Wrapper = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e1cz94y50"
} : {
  target: "e1cz94y50",
  label: "Wrapper"
})(process.env.NODE_ENV === "production" ? {
  name: "1orqj0m",
  styles: "order:1;display:flex;align-items:center"
} : {
  name: "1orqj0m",
  styles: "order:1;display:flex;align-items:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvSGVhZGVyL2NvbXBvbmVudHMvTGFuZ3VhZ2VTd2l0Y2gudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU9nQiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL0hlYWRlci9jb21wb25lbnRzL0xhbmd1YWdlU3dpdGNoLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB7IHVzZVN0YXRlIH0gZnJvbSBcInJlYWN0XCJcbmltcG9ydCB7IHVzZVJvdXRlciB9IGZyb20gXCJuZXh0L3JvdXRlclwiXG5pbXBvcnQgdHcgZnJvbSBcInR3aW4ubWFjcm9cIlxuXG5pbXBvcnQgeyBMYW5ndWFnZVN3aXRjaCBhcyBMYW5ndWFnZVN3aXRjaENvbXBvbmVudCB9IGZyb20gXCJAY29tcG9uZW50cy92My9Td2l0Y2hcIlxuaW1wb3J0IHVzZUxhbmcgZnJvbSBcIkBob29rcy91c2VMYW5nXCJcblxuY29uc3QgV3JhcHBlciA9IHR3LmRpdmBcbiAgICBmbGV4IGl0ZW1zLWNlbnRlciBvcmRlci0xXG5gXG5cbmNvbnN0IExhbmd1YWdlU3dpdGNoID0gKCkgPT4ge1xuICAgIGNvbnN0IHJvdXRlciA9IHVzZVJvdXRlcigpXG4gICAgY29uc3QgW2NoZWNrZWQsIHNldENoZWNrZWRdID0gdXNlU3RhdGUocm91dGVyLmxvY2FsZSA9PT0gXCJpZFwiKVxuICAgIGNvbnN0IHsgdXBkYXRlTGFuZyB9ID0gdXNlTGFuZygpXG5cbiAgICBjb25zdCBoYW5kbGVDaGFuZ2UgPSAoKSA9PiB7XG4gICAgICAgIHNldENoZWNrZWQoIWNoZWNrZWQpXG5cbiAgICAgICAgY29uc3QgbGFuZyA9IGNoZWNrZWQgPyBcImVuXCIgOiBcImlkXCJcbiAgICAgICAgdXBkYXRlTGFuZyh7IGxhbmcgfSlcblxuICAgICAgICBzZXRUaW1lb3V0KCgpID0+IHtcbiAgICAgICAgICAgIHJvdXRlci5yZXBsYWNlKHJvdXRlci5hc1BhdGgsIHJvdXRlci5hc1BhdGgsIHsgbG9jYWxlOiBsYW5nIH0pXG4gICAgICAgIH0sIDMwMClcbiAgICB9XG5cbiAgICByZXR1cm4gKFxuICAgICAgICA8V3JhcHBlcj5cbiAgICAgICAgICAgIDxMYW5ndWFnZVN3aXRjaENvbXBvbmVudCBhY3RpdmU9e2NoZWNrZWR9IG9uQ2hhbmdlPXtoYW5kbGVDaGFuZ2V9IC8+XG4gICAgICAgIDwvV3JhcHBlcj5cbiAgICApXG59XG5cbmV4cG9ydCBkZWZhdWx0IExhbmd1YWdlU3dpdGNoXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

const LanguageSwitch = () => {
  const router = useRouter();
  const [checked, setChecked] = useState(router.locale === "id");
  const {
    updateLang
  } = useLang();

  const handleChange = () => {
    setChecked(!checked);
    const lang = checked ? "en" : "id";
    updateLang({
      lang
    });
    setTimeout(() => {
      router.replace(router.asPath, router.asPath, {
        locale: lang
      });
    }, 300);
  };

  return <Wrapper>
            <LanguageSwitchComponent active={checked} onChange={handleChange} />
        </Wrapper>;
};

export default LanguageSwitch;