/* eslint-disable import/prefer-default-export */
import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchStakingBalancesMemberAction = createAsyncThunk<StakingBalanceApi[], {
  hash: string;
}>("staking/fetchStakingBalancesMember", async ({
  hash
}) => {
  const response = await baseFetchApi({
    url: `/staking/balances/${hash}`,
    method: "GET"
  });
  return response;
});