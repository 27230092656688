import { HYDRATE } from "next-redux-wrapper";
import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQuery, { HelpBaseQueryMeta } from "./helpBaseQuery";
const helpPostsApi = createApi({
  reducerPath: "helpPostsApi",
  baseQuery: fetchBaseQuery,
  endpoints: builder => ({
    getPostsByCategory: builder.query<PostsByCategoryModel, {
      id: string | number;
      page?: number;
    }>({
      query: arg => {
        const {
          id,
          page = 1
        } = arg;
        return {
          method: "GET",
          url: `posts?categories=${id}&per_page=6&page=${page}&_fields[]=slug&_fields[]=title&_fields[]=id`
        };
      },

      transformResponse(apiResponse: PostsByCategoryApi[], meta: HelpBaseQueryMeta) {
        const totalPages = Number(meta.headers["x-wp-totalpages"]);
        return {
          posts: apiResponse,
          totalPages
        };
      }

    }),
    getPosts: builder.query<PostsModel[], void | string | number>({
      query: () => ({
        method: "GET",
        url: `posts?_embed&per_page=7&page=1&_fields[]=slug&_fields[]=title&_fields[]=id`
      })
    }),
    getSearchPosts: builder.query<PostsByCategoryModel, {
      search: string;
      page: string | number | void;
    }>({
      query: arg => {
        const {
          search,
          page
        } = arg;
        return {
          method: "GET",
          url: `posts?search=${search}&per_page=8&page=${page}&_fields[]=slug&_fields[]=title&_fields[]=id`
        };
      },

      transformResponse(apiResponse: PostsByCategoryApi[], meta: HelpBaseQueryMeta) {
        const totalPages = Number(meta.headers["x-wp-totalpages"]);
        return {
          posts: apiResponse,
          totalPages
        };
      }

    }),
    getAllPosts: builder.query<PostsByCategoryModel, {
      page: number;
    }>({
      query: ({
        page
      } = {
        page: 1
      }) => ({
        method: "GET",
        url: `posts?per_page=100&page=${page}&_fields[]=slug&_fields[]=title&_fields[]=id&_fields[]=date_gmt&_fields[]=old_slugs`
      }),

      transformResponse(apiResponse: PostsByCategoryApi[], meta: HelpBaseQueryMeta) {
        const totalPages = Number(meta.headers["x-wp-totalpages"]);
        return {
          posts: apiResponse,
          totalPages
        };
      }

    })
  }),
  extractRehydrationInfo: (action, {
    reducerPath
  }) => {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }

    return null;
  }
});
export const {
  useGetPostsByCategoryQuery,
  useGetPostsQuery,
  useGetSearchPostsQuery
} = helpPostsApi;
export default helpPostsApi;