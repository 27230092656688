/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import storeRegistry from "@redux/registry";
import { fetchStatusAuth } from "@redux/status-auth/action";
import { setTab as setChangeTab } from "@redux/status-auth/slicer";
import { AnyAction } from "@reduxjs/toolkit";

const useStatusAuth = () => {
  const {
    statusAuth,
    isLoading
  } = useAppSelector(state => state.statusAuth);
  const dispatch = useAppDispatch();
  const {
    isLoggedIn
  } = useAppSelector(state => state.auth);

  const setTab = (tab: string) => {
    dispatch(setChangeTab({
      tab
    }));
  };

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchStatusAuth());
    }
  }, [dispatch]);
  useEffect(() => {
    ;

    (async () => {
      if (statusAuth?.phone) {
        const {
          default: tiktokPixelAnalytic
        } = await import("@lib/tiktok-pixel-analytic");
        tiktokPixelAnalytic.identify("", {
          phone_number: `+${statusAuth?.phone}`
        });
      }
    })();
  }, [statusAuth?.phone]);
  return {
    statusAuth,
    isLoading,
    setTab
  };
};

export const fetchstatus = () => {
  storeRegistry.getStore().dispatch(((fetchStatusAuth() as unknown) as AnyAction));
};
export default useStatusAuth;