/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAds } from "./actions";
const adsSlicer = createSlice({
  name: "ads",
  initialState: {
    ads: (null as AdsModels | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchAds.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchAds.fulfilled.type]: (state, action: PayloadAction<AdsModels>) => {
      state.ads = action.payload;
      state.isLoading = false;
    }
  }
});
export default adsSlicer;