import baseFetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface CancelInternalArg {
  hash?: string;
  token?: string;
}
export const fetchCancelInternalTf = createAsyncThunk<string, CancelInternalArg>("history/cancelInternalTransfer", async ({
  hash
}) => {
  const auth = getAuth();
  const formData = new FormData();
  const {
    token
  } = auth;
  formData.append("hash", hash || "");
  formData.append("token", token || "");
  const response = await baseFetchApi({
    url: "/cancelinternaltransfer",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData
  });
  return response;
});