import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface SummaryChartArgs {
  period: "all" | "P24H" | "P1W" | "P1M" | "P3M" | "P1Y";
}
export const fetchSummaryChartAction = createAsyncThunk<PortfolioSummaryChartAPI, SummaryChartArgs>("portfolio/fetchSummaryChart", async ({
  period
}) => {
  const response = await baseFetchApiV3<BaseResponseAPIV3<PortfolioSummaryChartAPI>>({
    url: "/pnl/summary/chart",
    params: {
      p: period
    }
  });
  return response.data;
});