import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchHistoryTransIdr, fetchHistoryTransIdrPagination } from "./actions";
const historyIdrSlicer = createSlice({
  name: "historyIdr",
  initialState: {
    openDetailHistory: (null as HistoryIdrModel | null),
    historyIdr: (null as HistoryIdrModel[] | null),
    isLoading: false
  },
  reducers: {
    setOpenDetailHistoryIdr: (state, action: PayloadAction<HistoryIdrModel>) => {
      state.openDetailHistory = action.payload;
    },
    clearOpenDetailHistoryIdr: state => {
      state.openDetailHistory = null;
    }
  },
  extraReducers: {
    [fetchHistoryTransIdr.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchHistoryTransIdr.fulfilled.type]: (state, action: PayloadAction<HistoryIdrApi[]>) => {
      state.historyIdr = action.payload.map(history => ({
        transaction_id: history.transaction_id,
        date: history.date,
        initial_date: history.initial_date,
        amount: history.amount,
        fee: history.fee,
        vat: history.vat,
        status: history.status,
        payment_type: history.payment_type,
        payment_branch: history.payment_branch,
        payment_name: history.payment_name,
        payment_account: history.payment_account,
        payment_logo: history.payment_logo,
        receipt: history.receipt,
        ewallet_data: history.ewallet_data,
        sms_fee: history.sms_fee,
        notes: history.notes,
        hash: history.hash,
        random_number: history.random_number,
        trans_type: history.trans_type,
        from_address: history.from_address,
        to_address: history.to_address,
        payment_bank_recommendations_id: history.payment_bank_recommendations_id,
        meta_data: history.meta_data
      }));
      state.isLoading = false;
    },
    [fetchHistoryTransIdrPagination.fulfilled.type]: (state, action: PayloadAction<HistoryIdrApi[]>) => {
      state.historyIdr?.push(...action.payload.map(history => ({
        transaction_id: history.transaction_id,
        date: history.date,
        initial_date: history.initial_date,
        amount: history.amount,
        fee: history.fee,
        vat: history.vat,
        status: history.status,
        payment_type: history.payment_type,
        payment_branch: history.payment_branch,
        payment_name: history.payment_name,
        payment_account: history.payment_account,
        payment_logo: history.payment_logo,
        receipt: history.receipt,
        ewallet_data: history.ewallet_data,
        sms_fee: history.sms_fee,
        notes: history.notes,
        hash: history.hash,
        random_number: history.random_number,
        trans_type: history.trans_type,
        from_address: history.from_address,
        to_address: history.to_address,
        payment_bank_recommendations_id: history.payment_bank_recommendations_id,
        meta_data: history.meta_data
      })));
    }
  }
});
export const {
  setOpenDetailHistoryIdr,
  clearOpenDetailHistoryIdr
} = historyIdrSlicer.actions;
export default historyIdrSlicer;