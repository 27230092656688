import Overlay from "@components/Overlay";
import useContentOverlay from "@hooks/useContentOverlay";

const ContentOverlay = () => {
  const {
    isOpen
  } = useContentOverlay();
  return <Overlay in={isOpen} className='absolute' />;
};

export default ContentOverlay;