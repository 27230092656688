import _styled from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

/* eslint-disable react/jsx-props-no-spreading */
import * as LogoComponents from "./images";

const StyledLogo = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e1vuuyf0"
} : {
  target: "e1vuuyf0",
  label: "StyledLogo"
})(process.env.NODE_ENV === "production" ? {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center"
} : {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjMvTG9nby9Mb2dvLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFLNkIiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy92My9Mb2dvL0xvZ28udHN4Iiwic291cmNlc0NvbnRlbnQiOlsiLyogZXNsaW50LWRpc2FibGUgcmVhY3QvanN4LXByb3BzLW5vLXNwcmVhZGluZyAqL1xuaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCJcblxuaW1wb3J0ICogYXMgTG9nb0NvbXBvbmVudHMgZnJvbSBcIi4vaW1hZ2VzXCJcblxuY29uc3QgU3R5bGVkTG9nbyA9IHN0eWxlZC5kaXZgXG4gICAgd2lkdGg6IGZpdC1jb250ZW50O1xuICAgIGhlaWdodDogZml0LWNvbnRlbnQ7XG5cbiAgICBkaXNwbGF5OiBpbmxpbmUtZmxleDtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuYFxuXG5leHBvcnQgaW50ZXJmYWNlIExvZ29Qcm9wcyBleHRlbmRzIFJlYWN0LlNWR1Byb3BzPFNWR1NWR0VsZW1lbnQ+IHtcbiAgICAvKipcbiAgICAgKiBMb2dvIG5hbWVcbiAgICAgKi9cbiAgICBsb2dvOiBrZXlvZiB0eXBlb2YgTG9nb0NvbXBvbmVudHNcbiAgICAvKipcbiAgICAgKiB3cmFwcGVyIGNsYXNzbmFtZVxuICAgICAqL1xuICAgIHdyYXBwZXJDbGFzc25hbWU/OiBzdHJpbmdcbn1cblxuY29uc3QgTG9nbzogUmVhY3QuRkM8TG9nb1Byb3BzPiA9ICh7IGxvZ28sIHdyYXBwZXJDbGFzc25hbWUsIC4uLnByb3BzIH06IExvZ29Qcm9wcykgPT4ge1xuICAgIGNvbnN0IENvbXBvbmVudCA9IExvZ29Db21wb25lbnRzW2xvZ29dXG5cbiAgICByZXR1cm4gKFxuICAgICAgICA8U3R5bGVkTG9nbyBjbGFzc05hbWU9e3dyYXBwZXJDbGFzc25hbWV9PlxuICAgICAgICAgICAgPENvbXBvbmVudCB7Li4ucHJvcHN9IC8+XG4gICAgICAgIDwvU3R5bGVkTG9nbz5cbiAgICApXG59XG5cbkxvZ28uZGVmYXVsdFByb3BzID0ge1xuICAgIHdyYXBwZXJDbGFzc25hbWU6IHVuZGVmaW5lZFxufVxuXG5leHBvcnQgZGVmYXVsdCBMb2dvXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export interface LogoProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Logo name
   */
  logo: keyof typeof LogoComponents;
  /**
   * wrapper classname
   */

  wrapperClassname?: string;
}

const Logo: React.FC<LogoProps> = ({
  logo,
  wrapperClassname,
  ...props
}: LogoProps) => {
  const Component = LogoComponents[logo];
  return <StyledLogo className={wrapperClassname}>
            <Component {...props} />
        </StyledLogo>;
};

Logo.defaultProps = {
  wrapperClassname: undefined
};
export default Logo;