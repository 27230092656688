/* eslint-disable import/prefer-default-export */
export enum UserStatus {
  Unverified = 0,
  Active = 1,
  Suspend = 2,
  Verified = 7,
  Pending = 8,
}
export interface ProfileModel {
  userId: number;
  fullName: string;
  email: string;
  status: UserStatus;
  riskLevelName: string;
  riskLevelType: number;
  dob?: string | null;
  city?: string;
  lang: string;
  alias: string;
  isAllowChangePassword: boolean;
  identityNumber: string;
}