import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchPortfolioSummaryAction = createAsyncThunk<PortfolioSummaryAPI>("portfolio/fetchPortfolioSummary", async (_, {
  rejectWithValue
}) => {
  try {
    const response = await baseFetchApiV3({
      url: "/pnl/summary",
      method: "GET"
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});