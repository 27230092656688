/* eslint-disable import/prefer-default-export */
import fetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchBuySellVolume = createAsyncThunk("guidance/fetchBuySellVolume", async ({
  product_id
}: BuySellVolumePayload) => {
  const response = await fetchApi({
    url: "/functions/guidance",
    method: "GET",
    params: {
      account_id: product_id
    }
  });
  return response;
});