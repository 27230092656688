import _styled from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

/* eslint-disable react/jsx-props-no-spreading */
import IconComponents from "./images";
export type IconType = keyof typeof IconComponents;

const StyledIcon = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "e187b2tq0"
} : {
  target: "e187b2tq0",
  label: "StyledIcon"
})(process.env.NODE_ENV === "production" ? {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center"
} : {
  name: "17x35y8",
  styles: "width:fit-content;height:fit-content;display:inline-flex;align-items:center;justify-content:center",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvdjMvSWNvbnMvSWNvbnMudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQU82QiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL3YzL0ljb25zL0ljb25zLnRzeCIsInNvdXJjZXNDb250ZW50IjpbIi8qIGVzbGludC1kaXNhYmxlIHJlYWN0L2pzeC1wcm9wcy1uby1zcHJlYWRpbmcgKi9cbmltcG9ydCBzdHlsZWQgZnJvbSBcIkBlbW90aW9uL3N0eWxlZFwiXG5cbmltcG9ydCBJY29uQ29tcG9uZW50cyBmcm9tIFwiLi9pbWFnZXNcIlxuXG5leHBvcnQgdHlwZSBJY29uVHlwZSA9IGtleW9mIHR5cGVvZiBJY29uQ29tcG9uZW50c1xuXG5jb25zdCBTdHlsZWRJY29uID0gc3R5bGVkLmRpdmBcbiAgICB3aWR0aDogZml0LWNvbnRlbnQ7XG4gICAgaGVpZ2h0OiBmaXQtY29udGVudDtcblxuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgIGFsaWduLWl0ZW1zOiBjZW50ZXI7XG4gICAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7XG5gXG5cbmV4cG9ydCBpbnRlcmZhY2UgSWNvbnNQcm9wcyBleHRlbmRzIFJlYWN0LlNWR1Byb3BzPFNWR1NWR0VsZW1lbnQ+IHtcbiAgICAvKipcbiAgICAgKiBJY29uIG5hbWVcbiAgICAgKi9cbiAgICBpY29uOiBJY29uVHlwZVxuICAgIC8qKlxuICAgICAqIHdyYXBwZXIgY2xhc3NuYW1lXG4gICAgICovXG4gICAgd3JhcHBlckNsYXNzbmFtZT86IHN0cmluZ1xuICAgIC8qKlxuICAgICAqIERhdGEgdGVzdCBpZFxuICAgICAqL1xuICAgIGRhdGFUZXN0SWQ/OiBzdHJpbmdcbn1cblxuY29uc3QgSWNvbnM6IFJlYWN0LkZDPEljb25zUHJvcHM+ID0gKHsgaWNvbiwgd3JhcHBlckNsYXNzbmFtZSwgZGF0YVRlc3RJZCwgLi4ucHJvcHMgfTogSWNvbnNQcm9wcykgPT4ge1xuICAgIGNvbnN0IENvbXBvbmVudCA9IEljb25Db21wb25lbnRzW2ljb25dXG5cbiAgICByZXR1cm4gKFxuICAgICAgICA8U3R5bGVkSWNvbiBkYXRhLXRlc3RpZD17ZGF0YVRlc3RJZCB8fCBcInJla3UtaWNvblwifSBkYXRhLW5hbWU9e2BpY29uLSR7aWNvbn1gfSBjbGFzc05hbWU9e3dyYXBwZXJDbGFzc25hbWV9PlxuICAgICAgICAgICAgPENvbXBvbmVudCB7Li4ucHJvcHN9IC8+XG4gICAgICAgIDwvU3R5bGVkSWNvbj5cbiAgICApXG59XG5cbkljb25zLmRlZmF1bHRQcm9wcyA9IHtcbiAgICB3cmFwcGVyQ2xhc3NuYW1lOiB1bmRlZmluZWQsXG4gICAgd2lkdGg6IDE2LFxuICAgIGhlaWdodDogMTYsXG4gICAgZGF0YVRlc3RJZDogXCJyZWt1LWljb25cIlxufVxuXG5leHBvcnQgZGVmYXVsdCBJY29uc1xuIl19 */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export interface IconsProps extends React.SVGProps<SVGSVGElement> {
  /**
   * Icon name
   */
  icon: IconType;
  /**
   * wrapper classname
   */

  wrapperClassname?: string;
  /**
   * Data test id
   */

  dataTestId?: string;
}

const Icons: React.FC<IconsProps> = ({
  icon,
  wrapperClassname,
  dataTestId,
  ...props
}: IconsProps) => {
  const Component = IconComponents[icon];
  return <StyledIcon data-testid={dataTestId || "reku-icon"} data-name={`icon-${icon}`} className={wrapperClassname}>
            <Component {...props} />
        </StyledIcon>;
};

Icons.defaultProps = {
  wrapperClassname: undefined,
  width: 16,
  height: 16,
  dataTestId: "reku-icon"
};
export default Icons;