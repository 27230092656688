import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { isMobile } from "react-device-detect";
import { css } from "@emotion/react";
interface ContainerProps {
  className?: string;
}
/**
 * @deprecated
 * use Container v2 instead
 */

const Container: React.FC<ContainerProps> = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e1gkjmde0"
} : {
  target: "e1gkjmde0",
  label: "Container"
})("width:90%;min-width:1210px;max-width:1400px;box-sizing:border-box;margin:0px auto;padding:0px 20px;", isMobile && (process.env.NODE_ENV === "production" ? {
  name: "1vfz20d",
  styles: "@media screen and (max-width: 1024px){min-width:-webkit-fill-available;}"
} : {
  name: "vh9png-Container",
  styles: "@media screen and (max-width: 1024px){min-width:-webkit-fill-available;};label:Container;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvQ29udGFpbmVyL0NvbnRhaW5lci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBdUJPIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvQ29udGFpbmVyL0NvbnRhaW5lci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBpc01vYmlsZSB9IGZyb20gXCJyZWFjdC1kZXZpY2UtZGV0ZWN0XCJcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IHsgY3NzIH0gZnJvbSBcIkBlbW90aW9uL3JlYWN0XCJcblxuaW50ZXJmYWNlIENvbnRhaW5lclByb3BzIHtcbiAgICBjbGFzc05hbWU/OiBzdHJpbmdcbn1cblxuLyoqXG4gKiBAZGVwcmVjYXRlZFxuICogdXNlIENvbnRhaW5lciB2MiBpbnN0ZWFkXG4gKi9cbmNvbnN0IENvbnRhaW5lcjogUmVhY3QuRkM8Q29udGFpbmVyUHJvcHM+ID0gc3R5bGVkLmRpdmBcbiAgICB3aWR0aDogOTAlO1xuICAgIG1pbi13aWR0aDogMTIxMHB4O1xuICAgIG1heC13aWR0aDogMTQwMHB4O1xuXG4gICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgICBtYXJnaW46IDBweCBhdXRvO1xuICAgIHBhZGRpbmc6IDBweCAyMHB4O1xuXG4gICAgJHtpc01vYmlsZSAmJlxuICAgIGNzc2BcbiAgICAgICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogMTAyNHB4KSB7XG4gICAgICAgICAgICBtaW4td2lkdGg6IC13ZWJraXQtZmlsbC1hdmFpbGFibGU7XG4gICAgICAgIH1cbiAgICBgfVxuYFxuXG5leHBvcnQgZGVmYXVsdCBDb250YWluZXJcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
}), ";" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvQ29udGFpbmVyL0NvbnRhaW5lci50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBYXNEIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvQ29udGFpbmVyL0NvbnRhaW5lci50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgeyBpc01vYmlsZSB9IGZyb20gXCJyZWFjdC1kZXZpY2UtZGV0ZWN0XCJcbmltcG9ydCB7IHN0eWxlZCB9IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IHsgY3NzIH0gZnJvbSBcIkBlbW90aW9uL3JlYWN0XCJcblxuaW50ZXJmYWNlIENvbnRhaW5lclByb3BzIHtcbiAgICBjbGFzc05hbWU/OiBzdHJpbmdcbn1cblxuLyoqXG4gKiBAZGVwcmVjYXRlZFxuICogdXNlIENvbnRhaW5lciB2MiBpbnN0ZWFkXG4gKi9cbmNvbnN0IENvbnRhaW5lcjogUmVhY3QuRkM8Q29udGFpbmVyUHJvcHM+ID0gc3R5bGVkLmRpdmBcbiAgICB3aWR0aDogOTAlO1xuICAgIG1pbi13aWR0aDogMTIxMHB4O1xuICAgIG1heC13aWR0aDogMTQwMHB4O1xuXG4gICAgYm94LXNpemluZzogYm9yZGVyLWJveDtcbiAgICBtYXJnaW46IDBweCBhdXRvO1xuICAgIHBhZGRpbmc6IDBweCAyMHB4O1xuXG4gICAgJHtpc01vYmlsZSAmJlxuICAgIGNzc2BcbiAgICAgICAgQG1lZGlhIHNjcmVlbiBhbmQgKG1heC13aWR0aDogMTAyNHB4KSB7XG4gICAgICAgICAgICBtaW4td2lkdGg6IC13ZWJraXQtZmlsbC1hdmFpbGFibGU7XG4gICAgICAgIH1cbiAgICBgfVxuYFxuXG5leHBvcnQgZGVmYXVsdCBDb250YWluZXJcbiJdfQ== */"));

export default Container;