import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export enum PlCalendarParams {
  day = "D",
  month = "M",
}
export interface PlCalendarArgs {
  p: number;
  f?: PlCalendarParams;
}
export const fetchPlCalendarbar = createAsyncThunk<PlCalendarAPI[], PlCalendarArgs>("pl-calendar/plCalendarBar", async ({
  p,
  f
}) => {
  const response = await baseFetchApiV3({
    url: "/pnl/summary/calendar",
    method: "GET",
    params: {
      p,
      f
    }
  });
  return response.data;
});