import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchTaxReportAction = createAsyncThunk<TaxReportAPI, number>("taxReport/fetchTaxReport", async year => {
  const response = await baseFetchApiV3<TaxReportAPI>({
    url: "/tax-reporting",
    method: "GET",
    params: {
      year
    }
  });
  return response;
});