import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchBalancesMemberAction } from "./actions";
const balancesMemberSlice = createSlice({
  name: "balancesMember",
  initialState: {
    balancesMember: (null as BalancesModel | null),
    isLoading: false
  },
  reducers: {
    updateBalance: (state, action) => {
      state.balancesMember = { ...state.balancesMember,
        ...action.payload
      };
    }
  },
  extraReducers: {
    [fetchBalancesMemberAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchBalancesMemberAction.fulfilled.type]: (state, action: PayloadAction<BalancesApi>) => {
      state.balancesMember = action.payload;
      state.isLoading = false;
    }
  }
});
export const {
  updateBalance
} = balancesMemberSlice.actions;
export default balancesMemberSlice;