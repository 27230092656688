import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface SendCoinMultipleChainArgs {
  coinCode: string;
  networkId: string;
  address: string;
  tag?: string;
  amount: number;
  otp: string;
}
export const sendCoinMultipleChain = createAsyncThunk<SuccessV2API, SendCoinMultipleChainArgs>("sendCoin/sendCoinMultipleChain", async ({
  coinCode,
  networkId,
  address,
  tag,
  amount,
  otp
}, {
  rejectWithValue
}) => {
  try {
    let data: object = {
      to: address,
      amount,
      otp
    };

    if (tag) {
      data = { ...data,
        tag
      };
    }

    const response = await baseFetchApi({
      url: `/wallet/${coinCode}/withdraw/${networkId}`,
      method: "POST",
      data
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});
export interface SendCoinArgs {
  coinId?: number;
  address: string;
  tag?: string;
  amount: number;
  otp: string;
}
export const sendCoin = createAsyncThunk<SuccessV2API, SendCoinArgs>("sendCoin/sendCoin", async ({
  coinId,
  address,
  tag,
  amount,
  otp
}, {
  rejectWithValue
}) => {
  try {
    let data: object = {
      to: address,
      amount,
      otp,
      currencycode_send: coinId
    };

    if (tag) {
      data = { ...data,
        tag
      };
    }

    const response = await baseFetchApi({
      url: "/sendcoin",
      method: "POST",
      data
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});