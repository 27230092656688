import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BLOG_API_URL } from "@config/config";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

class CustomError extends Error {
  response: {
    data?: string;
    status?: number;
  } = {
    data: undefined,
    status: 500
  };

  constructor(message: string, errno?: number) {
    super(message);
    this.response.data = message;
    this.response.status = errno;
  }

}

const fetchBaseQuery: BaseQueryFn<{
  url: string;
  method: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
}, unknown, unknown> = async ({
  url,
  method,
  data
}) => {
  try {
    const result = await axios({
      url: BLOG_API_URL + url,
      method,
      data
    });

    if (result.data?.error) {
      throw new CustomError(result.data?.error, result.data?.errno);
    }

    return {
      data: result.data,
      meta: {
        headers: result.headers
      }
    };
  } catch (axiosError) {
    const err = (axiosError as AxiosError);
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data
      }
    };
  }
};

export default fetchBaseQuery;