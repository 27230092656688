import HeaderComponent from "@components/Header";
import useAuth from "@hooks/useAuth";
import useProfile from "@hooks/useProfile";

const Header = () => {
  const {
    auth: {
      isLoggedIn
    }
  } = useAuth();
  const {
    profile,
    isLoading
  } = useProfile();
  return <HeaderComponent profile={isLoggedIn ? {
    riskLevelType: profile?.riskLevelType,
    fullName: profile?.fullName,
    email: profile?.email,
    status: profile?.status,
    riskLevelName: profile?.riskLevelName,
    isLoading
  } : undefined} />;
};

export default Header;