/* eslint-disable import/prefer-default-export */
import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchBalances = createAsyncThunk<BalancesApi, string | undefined>("coins/fetchBalances", async hash => {
  const response = await baseFetchApiV3({
    url: hash ? `/balance/${hash}` : "/balance",
    method: "GET"
  });
  return { ...response,
    rp: response.rp ?? 0,
    rp_R: response.rp_R ?? 0
  };
});