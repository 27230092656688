import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const sendOtpEmail = createAsyncThunk<SuccessAPI, SendOTPEmailApi>("sendOtpEmail/sendOtpEmail", async ({
  amount,
  internaltransfer
}) => {
  const response = await baseFetchApiV3({
    url: "/sendemailotpwd",
    method: "POST",
    data: {
      amount,
      internaltransfer
    }
  });
  return response;
});