import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { API_URL } from "@config/config";
import { getAuth } from "@helpers/auth";
import { BaseQueryFn } from "@reduxjs/toolkit/query";

class CustomError extends Error {
  response: {
    data?: string;
    status?: number;
  } = {
    data: undefined,
    status: 500
  };

  constructor(message: string, errno?: number) {
    super(message);
    this.response.data = message;
    this.response.status = errno;
  }

}

const fetchBaseQuery: BaseQueryFn<{
  url: string;
  method: AxiosRequestConfig["method"];
  data?: AxiosRequestConfig["data"];
}, unknown, unknown> = async ({
  url,
  method,
  data
}) => {
  try {
    const {
      token
    } = getAuth();
    let headers: {} = {
      "Cache-Control": "no-cache"
    };

    if (token) {
      headers = { ...headers,
        token
      };
    }

    const result = await axios({
      url: API_URL + url,
      method,
      data,
      headers
    });

    if (result.data?.error) {
      throw new CustomError(result.data?.error, result.data?.errno);
    }

    return {
      data: result.data
    };
  } catch (axiosError) {
    const err = (axiosError as AxiosError);
    return {
      error: {
        status: err.response?.status || null,
        data: err.response?.data || null
      }
    };
  }
};

export default fetchBaseQuery;