import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export const initialValue = {
  from: "",
  to: "",
  redirect: ""
};
const navigationSlicer = createSlice({
  name: "navigation",
  initialState: initialValue,
  reducers: {
    setNavigate: (state, action: PayloadAction<NavigationModel>) => ({ ...state,
      ...action.payload
    }),
    resetValue: () => initialValue
  }
});
export const {
  setNavigate,
  resetValue
} = navigationSlicer.actions;
export default navigationSlicer;