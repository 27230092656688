import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { WithdrawStep } from "@type/enum/withdraw-idr";
import { InternaltransferType, SendCoinType, WalletPreferencesModel } from "@type/model/wallet-preferences.d";
const walletPreferencesSlicer = createSlice({
  name: "walletPreferences",
  initialState: {
    withdrawStep: WithdrawStep.Default,
    depoStep: "default",
    selectedWithdrawMethod: "",
    amount: 0,
    amountAfterFee: 0,
    selectedNetwork: "",
    pageSource: "Wallet Page",
    isToggleHide: false,
    isReceiveFullAmount: true,
    metadata: (null as MetaDataAPI),
    internal: ((null as unknown) as InternaltransferType),
    sendCoin: ((null as unknown) as SendCoinType)
  },
  reducers: {
    changeWalletPreferences: (state, action: PayloadAction<WalletPreferencesModel>) => ({ ...state,
      ...action.payload
    })
  }
});
export const {
  changeWalletPreferences
} = walletPreferencesSlicer.actions;
export default walletPreferencesSlicer;