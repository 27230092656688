import dynamic from "next/dynamic";
import { useTranslation } from "next-i18next";
import { useErrorBoundary } from "react-use-error-boundary";
import Container from "@components/Container";
import { IP_SUSPENDED, MAINTENANCE, OTC_MAINTENANCE } from "@const/errno";
import useError from "@hooks/useError";
import useSettings from "@hooks/useSettings";
import Layout from "@layouts/Layout";
const IpSuspended = dynamic(() => import("@modules/IpSuspended"), {
  ssr: false
});
const Maintenance = dynamic(() => import("@modules/Maintenance"), {
  ssr: false
});
const FailedIcon = dynamic(() => import("@assets/svgx/FailedIcon"), {
  ssr: false
});
interface ErrorBoundaryProps extends React.PropsWithChildren<{}> {}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({
  children
}: ErrorBoundaryProps) => {
  const {
    t
  } = useTranslation();
  const [error] = useErrorBoundary();
  const {
    error: apiError
  } = useError(); // fetch settings to trigger suspended ip error

  useSettings();

  if (error) {
    return <Container>
                <div className='h-screen w-full flex flex-col items-center justify-center gap-3'>
                    <FailedIcon width={112} height={112} />
                    <span className='text-neutral-700 dark:text-white font-semibold text-xl text-center'>
                        {t("common:error_boundary.title")}
                    </span>
                    <span className='text-base text-neutral-500'>{t("common:error_boundary.description")}</span>
                </div>
            </Container>;
  }

  if (apiError.status) {
    if (apiError.code === IP_SUSPENDED) {
      return <IpSuspended note={apiError.message} />;
    }

    if (apiError.code === MAINTENANCE) {
      return <Maintenance code={apiError.code} note={apiError.message} />;
    }

    if (apiError.code === OTC_MAINTENANCE) {
      return <Layout>
                    <Maintenance code={apiError.code} note={apiError.message} />
                </Layout>;
    }
  } // eslint-disable-next-line react/jsx-no-useless-fragment


  return <>{children}</>;
};

export default ErrorBoundary;