// import cheetah from "./cheetah.svg"
// import dolphin from "./dolphin.svg"
// import leopard from "./leopard.svg"
// import owl from "./owl.svg"
// import tortoise from "./tortoise.svg"
// export { cheetah, dolphin, leopard, owl, tortoise }
import dynamic from "next/dynamic";
const PersonalityIllustration = {
  Cheetah: dynamic(() => import("./Cheetah")),
  Dolphin: dynamic(() => import("./Dolphin")),
  Leopard: dynamic(() => import("./Leopard")),
  Owl: dynamic(() => import("./Owl")),
  Tortoise: dynamic(() => import("./Tortoise"))
};
export default PersonalityIllustration;