import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface Args {
  coinId: number;
  period?: string;
}
export const fetchOTCChart = createAsyncThunk<number[][], Args>("otcChart/fetchOTCChart", async ({
  coinId,
  period = "1d"
}) => {
  const response = await baseFetchApi({
    url: `/otc/products/${coinId}/chart`,
    method: "GET",
    params: {
      d: period
    }
  });
  return response.sort();
});