import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaqTrade, fetchFaqTradeCount } from "./actions";
const faqTradeSlicer = createSlice({
  name: "faqTrade",
  initialState: {
    faqTrade: (null as FaqTradeAPI[] | null),
    isLoading: true,
    faqTradeCount: (null as FaqTradeCountAPI[] | null)
  },
  reducers: {},
  extraReducers: {
    [fetchFaqTrade.pending.type]: state => {
      state.isLoading = true;
      state.faqTrade = null;
    },
    [fetchFaqTrade.fulfilled.type]: (state, action: PayloadAction<FaqTradeAPI[]>) => {
      state.faqTrade = action.payload;
      state.isLoading = false;
    },
    [fetchFaqTrade.rejected.type]: state => {
      state.isLoading = false;
    },
    [fetchFaqTradeCount.pending.type]: state => {
      state.isLoading = true;
      state.faqTradeCount = null;
    },
    [fetchFaqTradeCount.fulfilled.type]: (state, action: PayloadAction<FaqTradeCountAPI[]>) => {
      state.faqTradeCount = action.payload;
      state.isLoading = false;
    },
    [fetchFaqTradeCount.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default faqTradeSlicer;