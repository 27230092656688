/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPendingOrder } from "./actions";

const convertToModel = (pendingOrders: PendingOrderApi[]): PendingOrderModel[] => pendingOrders.map(pending => {
  let percentage = Math.floor(100 - pending.oa / (pending.ta / pending.a) * 100);
  percentage = percentage < 0 ? 0 : percentage;
  return {
    id: pending.id,
    date: pending.ot,
    productId: pending.cid,
    migaId: pending.migaid,
    code: pending.c,
    price: pending.a,
    amount: pending.oa,
    filled: percentage,
    total: pending.t,
    transactionType: pending.tt ? "sell" : "buy"
  };
});

const pendingOrdersSlicer = createSlice({
  name: "pendingOrders",
  initialState: {
    pendingOrders: (null as PendingOrderModel[] | null),
    isLoading: false
  },
  reducers: {
    setPendingOrders: (state, action: PayloadAction<PendingOrderApi[]>) => {
      state.pendingOrders = convertToModel(action.payload);
    }
  },
  extraReducers: {
    [fetchPendingOrder.pending.type]: state => {
      state.pendingOrders = null;
      state.isLoading = true;
    },
    [fetchPendingOrder.fulfilled.type]: (state, action: PayloadAction<PendingOrdersApi>) => {
      state.pendingOrders = convertToModel(action.payload.order);
      state.isLoading = false;
    }
  }
});
export const {
  setPendingOrders
} = pendingOrdersSlicer.actions;
export default pendingOrdersSlicer;