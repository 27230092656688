import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchVirtualAccount } from "./actions";
const virtualAccountsSlicer = createSlice({
  name: "virtualAccounts",
  initialState: {
    virtualAccounts: (null as VirtualAccountModel[] | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchVirtualAccount.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchVirtualAccount.fulfilled.type]: (state, action: PayloadAction<VirtualAccountAPI[]>) => {
      state.virtualAccounts = action.payload.map(va => ({
        id: va.id,
        bank: va.bank,
        logo: va.logo
      }));
      state.isLoading = false;
    }
  }
});
export default virtualAccountsSlicer;