import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
let controller = new AbortController();
export interface FetchChartArgs {
  coinId: number;
  period: number;
}
export const fetchChart = createAsyncThunk<ChartAPI, FetchChartArgs>("chart/fetchChart", async ({
  period,
  coinId
}) => {
  controller.abort();
  controller = new AbortController();
  const response = await baseFetchApi({
    url: "/chart",
    params: {
      t: 2,
      f: period,
      id: coinId
    },
    signal: controller.signal
  });
  return response;
});