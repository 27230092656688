import fetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchBankUser = createAsyncThunk<BankUserAPI[]>("bankUsers/fetchBankUser", async () => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/bankuser",
    method: "POST",
    data: {
      token: auth.token
    }
  });
  return response;
});
export interface AddBankUserArgs {
  bankId: string;
  bankAccountNumber: string;
  bankName: string;
}
export const addBankUser = createAsyncThunk<SuccessV2API, AddBankUserArgs>("bankUsers/addBankUser", async ({
  bankId,
  bankAccountNumber
}, {
  dispatch
}) => {
  const response = await fetchApi({
    url: "/adduserbank",
    method: "POST",
    data: {
      bankname: bankId,
      user_bankaccno: bankAccountNumber
    }
  });
  dispatch(fetchBankUser());
  return response;
});
export const removeBankUser = createAsyncThunk<SuccessV2API, string>("bankUsers/removeBankUser", async (id, {
  dispatch
}) => {
  const response = await fetchApi({
    url: "/removeuserbank",
    method: "POST",
    data: {
      id
    }
  });
  dispatch(fetchBankUser());
  return response;
});