import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
let controller = new AbortController();
export interface FetchMarketTransactionsResponse {
  data: MarketTransactionsAPI;
  coinCode: string;
}
export const handleFetchMarketTransactions = async (coinCode: string) => {
  controller.abort();
  controller = new AbortController();
  const response = await baseFetchApi<MarketTransactionsAPI>({
    url: `/acctrade/${coinCode}_IDR`,
    method: "GET",
    signal: controller.signal
  });
  return {
    data: response,
    coinCode
  };
};
export const fetchMarketTransactions = createAsyncThunk<FetchMarketTransactionsResponse, string>("marketTransactions/fetchMarketTransactions", handleFetchMarketTransactions);
export const fetchMarketTransactionsAsync = createAsyncThunk<FetchMarketTransactionsResponse, string>("marketTransactions/fetchMarketTransactionsAsync", handleFetchMarketTransactions);