import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatAddress } from "@utils/address";
export const fetchCoinMultipleChainWalletBooks = createAsyncThunk<CoinMultipleChainWalletBooksAPI, string>("coinWalletBooks/fetchCoinMultipleChainWalletBooks", async coinCode => {
  const response = await baseFetchApi({
    url: `/wallet/${coinCode}/address/0/books`,
    method: "POST"
  });
  return response;
});
export const fetchCoinWalletBooks = createAsyncThunk<CoinWalletBooksAPI, number>("coinWalletBooks/fetchCoinWalletBooks", async coinId => {
  console.log("called here bro", coinId);
  const response = await baseFetchApi({
    url: `/walletbook`,
    method: "POST",
    data: {
      coin: coinId
    }
  });
  return response;
});
export interface AddCoinMultipleChainWalletBookArgs {
  coinCode: string;
  networkId: string;
  label: string;
  address: string;
  tagUrl?: string;
  tagAddress?: string;
}
export const addCoinMultipleChainWalletBook = createAsyncThunk<AddCoinMultipleChainWalletBookAPI, AddCoinMultipleChainWalletBookArgs>("coinWalletBooks/addCoinWalletBook", async ({
  coinCode,
  networkId,
  label,
  address,
  tagUrl,
  tagAddress
}) => {
  const response = await baseFetchApi({
    url: `/wallet/${coinCode}/address/${networkId}/book`,
    method: "POST",
    data: {
      wallet_book_address: formatAddress({
        address,
        tagAddress,
        tagUrl
      }),
      wallet_book_name: label
    }
  });
  return response;
});
export interface AddCoinWalletBookArgs {
  coinId: number;
  label: string;
  address: string;
  tagUrl?: string;
  tagAddress?: string;
}
export const addCoinWalletBook = createAsyncThunk<SuccessV2API, AddCoinWalletBookArgs>("coinWalletBooks/addCoinWalletBook", async ({
  coinId,
  label,
  address,
  tagUrl,
  tagAddress
}) => {
  const response = await baseFetchApi({
    url: `/savewallet`,
    method: "POST",
    data: {
      coin: coinId,
      addr: formatAddress({
        address,
        tagAddress,
        tagUrl
      }),
      walletname: label
    }
  });
  return response;
});