export enum TransactionType {
  All = 2,
  Deposit = 0,
  Withdraw = 1,
  Distribution = 88,
}
export enum FiatTransactionType {
  Deposit = 0,
  Withdraw = 1,
}
export enum CoinTransactionType {
  Deposit = 99,
  Withdraw = 2,
  Distribution = 88,
}
export enum WalletTransactionStatus {
  IN_PROCESS,
  SUCCESS,
  CANCELLED,
  FAILED,
  EMAIL_CONFIRMATION,
  BANK_PENDING,
  EWALLET_PENDING,
  WAITING_FOR_PAYMENT,
}
export interface WalletHistoryModel {
  id: string;
  hash: string;
  date: number;
  description: string;
  coinCode: string;
  transactionType: number;
  status: WalletTransactionStatus;
  chainName: string;
  walletLabel: string;
  address: {
    from: string;
    to: string;
  };
  amount: number;
  random: number;
  fee: {
    original_fee: number;
    sms_fee: number;
    vat: {
      idr: number;
      coin: number;
    };
  };
  netAmount: number;
  bs: string;
  tx: {
    hash: string;
    url: string;
  };
  note: string;
  notes: string;
  actions?: any;
}