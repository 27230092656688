import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface FetchFaqtrade {
  code: string;
}

const handleFetchFaqTradeCount = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_crypto?select=code,count()",
    method: "GET",
    params: {
      code: `eq.${code}`
    }
  });
  return response;
};

const handleFetchFaqTrade = async ({
  code
}: FetchFaqtrade) => {
  const response = await baseFetchApiV3({
    url: "/signals/faq_list_crypto",
    method: "GET",
    params: {
      code: `eq.${code}`,
      order: "order.asc"
    }
  });
  return response;
};

export const fetchFaqTrade = createAsyncThunk<FaqTradeAPI, FetchFaqtrade>("faqTrade/fetchFaqTrade", handleFetchFaqTrade);
export const fetchFaqTradeCount = createAsyncThunk<FaqTradeCountAPI, FetchFaqtrade>("faqTrade/fetchFaqTradeCount", handleFetchFaqTradeCount);