import { HYDRATE } from "next-redux-wrapper";
import { createApi } from "@reduxjs/toolkit/query/react";
import fetchBaseQuery from "./faqBaseQuery";
const filterQueryString = "_fields[]=id&_fields[]=slug&_fields[]=title&_fields[]=content&_fields[]=faq_category&_fields[]=date_gmt&_fields[]=type";
const faqPostsApi = createApi({
  reducerPath: "faqPostsApi",
  baseQuery: fetchBaseQuery,
  endpoints: builder => ({
    getFaqs: builder.query<FaqApi[], void>({
      query: () => ({
        method: "GET",
        url: `faq?_embed&faq_category=11&order=asc&per_page=100&page=1&${filterQueryString}`
      })
    }),
    getFaq: builder.query<FaqApi[], void | string>({
      query: slug => ({
        method: "GET",
        url: `faq?_embed&slug=${slug}&_fields[]=id&${filterQueryString}`
      })
    }),
    getFaqsByCategory: builder.query<FaqApi[], void | number>({
      query: categoryId => ({
        method: "GET",
        url: `faq?_embed&faq_category=${categoryId}&per_page=100&page=1&${filterQueryString}`
      })
    })
  }),
  extractRehydrationInfo: (action, {
    reducerPath
  }) => {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }

    return null;
  }
});
export const {
  useGetFaqsQuery,
  useGetFaqQuery,
  useGetFaqsByCategoryQuery
} = faqPostsApi;
export default faqPostsApi;