import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface GenerateTwoFaArgs {
  token: string;
  otp: string;
}
export const fetchGenerateTwoFa = createAsyncThunk<boolean, GenerateTwoFaArgs>("status/disableAuth", async ({
  token,
  otp
}) => {
  const formData = new FormData();
  formData.append("token", token);
  formData.append("otp", otp);
  const response = await baseFetchApi({
    url: "/generate2fa",
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    data: formData
  });
  return response;
});