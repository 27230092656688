/* eslint-disable import/prefer-default-export */
import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchBalancesMemberAction = createAsyncThunk<BalancesApi, {
  hash: string;
}>("balances/fetchBalancesMember", async ({
  hash
}) => {
  const response = await baseFetchApiV3({
    url: `/balance/${hash}`,
    method: "GET"
  });
  return response;
});