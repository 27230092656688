import { HYDRATE } from "next-redux-wrapper";
import { createApi } from "@reduxjs/toolkit/query/react";
import helpBaseQuery from "./helpBaseQuery";
const helpRedirectionApi = createApi({
  reducerPath: "helpRedirection",
  baseQuery: helpBaseQuery,
  endpoints: builder => ({
    getRedirection: builder.query<HelpRedirectionApi, void>({
      query: () => ({
        method: "GET",
        url: `redirection?per_page=200`
      })
    })
  }),
  extractRehydrationInfo: (action, {
    reducerPath
  }) => {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }

    return null;
  }
});
export const {
  useGetRedirectionQuery
} = helpRedirectionApi;
export default helpRedirectionApi;