import { getAuth } from "@helpers/auth";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSubmitTwoFa } from "./action";
const auth = getAuth();
const submitTwoFaSlicer = createSlice({
  name: "submitTwoFa",
  initialState: (({
    token: auth.token || null,
    otp: null,
    secret: null
  } as unknown) as SubmitTwoFa),
  reducers: {},
  extraReducers: {
    [fetchSubmitTwoFa.pending.type]: state => {
      state.token = undefined;
      state.otp = undefined;
      state.secret = undefined;
    },
    [fetchSubmitTwoFa.fulfilled.type]: (state, action: PayloadAction<SubmitTwoFa>) => {
      state.token = action.payload?.token;
      state.otp = action.payload?.otp;
      state.secret = action.payload?.secret;
    }
  }
});
export default submitTwoFaSlicer;