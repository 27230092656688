import _styled from "@emotion/styled/base";
import * as React from "react";
import { keyframes } from "@emotion/react";
const spinner = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const SVGAnimation = _styled("svg", process.env.NODE_ENV === "production" ? {
  target: "e1jz5ctq0"
} : {
  target: "e1jz5ctq0",
  label: "SVGAnimation"
})("animation:", spinner, " 1s linear infinite;" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvTG9hZGVyL2NvbXBvbmVudHMvT3ZhbC50c3giXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBZStCIiwiZmlsZSI6Ii92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvTG9hZGVyL2NvbXBvbmVudHMvT3ZhbC50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgKiBhcyBSZWFjdCBmcm9tIFwicmVhY3RcIlxuXG5pbXBvcnQgeyBrZXlmcmFtZXMgfSBmcm9tIFwiQGVtb3Rpb24vcmVhY3RcIlxuaW1wb3J0IHN0eWxlZCBmcm9tIFwiQGVtb3Rpb24vc3R5bGVkXCJcblxuY29uc3Qgc3Bpbm5lciA9IGtleWZyYW1lc2BcbiAgICAwJSB7XG4gICAgICAgIHRyYW5zZm9ybTogcm90YXRlKDBkZWcpO1xuICAgIH1cblxuICAgIDEwMCUge1xuICAgICAgICB0cmFuc2Zvcm06IHJvdGF0ZSgzNjBkZWcpO1xuICAgIH1cbmBcblxuY29uc3QgU1ZHQW5pbWF0aW9uID0gc3R5bGVkLnN2Z2BcbiAgICBhbmltYXRpb246ICR7c3Bpbm5lcn0gMXMgbGluZWFyIGluZmluaXRlO1xuYFxuXG5jb25zdCBPdmFsOiBSZWFjdC5GQzxMb2FkZXJQcm9wcz4gPSAoeyB3aWR0aCwgaGVpZ2h0LCBjb2xvciwgbGFiZWwsIHJhZGl1cyB9OiBMb2FkZXJQcm9wcykgPT4gKFxuICAgIDxTVkdBbmltYXRpb25cbiAgICAgICAgd2lkdGg9e3dpZHRofVxuICAgICAgICBoZWlnaHQ9e2hlaWdodH1cbiAgICAgICAgdmlld0JveD0nMCAwIDM4IDM4J1xuICAgICAgICB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnXG4gICAgICAgIHN0cm9rZT17Y29sb3J9XG4gICAgICAgIGFyaWEtbGFiZWw9e2xhYmVsfVxuICAgID5cbiAgICAgICAgPGcgZmlsbD0nbm9uZScgZmlsbFJ1bGU9J2V2ZW5vZGQnPlxuICAgICAgICAgICAgPGcgdHJhbnNmb3JtPSd0cmFuc2xhdGUoMSAxKScgc3Ryb2tlV2lkdGg9JzInPlxuICAgICAgICAgICAgICAgIDxjaXJjbGUgc3Ryb2tlT3BhY2l0eT0nLjUnIGN4PScxOCcgY3k9JzE4JyByPXtyYWRpdXN9IC8+XG4gICAgICAgICAgICAgICAgPHBhdGggZD0nTTM2IDE4YzAtOS45NC04LjA2LTE4LTE4LTE4JyAvPlxuICAgICAgICAgICAgPC9nPlxuICAgICAgICA8L2c+XG4gICAgPC9TVkdBbmltYXRpb24+XG4pXG5cbk92YWwuZGVmYXVsdFByb3BzID0ge1xuICAgIHJhZGl1czogMThcbn1cblxuZXhwb3J0IGRlZmF1bHQgT3ZhbFxuIl19 */"));

const Oval: React.FC<LoaderProps> = ({
  width,
  height,
  color,
  label,
  radius
}: LoaderProps) => <SVGAnimation width={width} height={height} viewBox='0 0 38 38' xmlns='http://www.w3.org/2000/svg' stroke={color} aria-label={label}>
        <g fill='none' fillRule='evenodd'>
            <g transform='translate(1 1)' strokeWidth='2'>
                <circle strokeOpacity='.5' cx='18' cy='18' r={radius} />
                <path d='M36 18c0-9.94-8.06-18-18-18' />
            </g>
        </g>
    </SVGAnimation>;

Oval.defaultProps = {
  radius: 18
};
export default Oval;