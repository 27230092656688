import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchPlacementAnnouncement } from "./actions";
const PlacementAnnouncementSlicer = createSlice({
  name: "placementAnnouncement",
  initialState: {
    placementAnnouncement: (null as PlacementAnnouncementAPI | null),
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchPlacementAnnouncement.fulfilled.type]: (state, action: PayloadAction<PlacementAnnouncementAPI>) => {
      state.placementAnnouncement = action.payload;
      state.isLoading = false;
    }
  }
});
export default PlacementAnnouncementSlicer;