import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export interface SetPrimaryPhoneArgs {
  token: string;
  otp: string;
  id: string;
}
export const fetchSetPrimaryPhone = createAsyncThunk<boolean, SetPrimaryPhoneArgs>("status/setPrimaryPhone", async ({
  token,
  otp,
  id
}, {
  rejectWithValue
}) => {
  try {
    const response = await baseFetchApiV3({
      url: "/user/phone-numbers/set-primary",
      method: "POST",
      data: {
        token,
        otp,
        id
      }
    });
    return response;
  } catch (err) {
    return rejectWithValue(err);
  }
});