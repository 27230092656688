/* eslint-disable import/prefer-default-export */
import blogFetchApi from "@api/blogApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { wrapTableWithFigure } from "@utils/html";
export const defaultPerPage = 12;
const defaultArgs = {
  withLatestPost: false,
  page: 1,
  categoryId: 2
};
const postsFields = ["id", "date_gmt", "slug", "title", "excerpt", "_links", "_embedded", "estimated_reading_time", "type", "old_slugs"].join();
export const fetchAllPosts = createAsyncThunk<{
  data: CampusPostAPI[];
  totalPages: number;
}, number | undefined>("analysis/fetchAllPosts", async (page = defaultArgs.page) => {
  const response = await blogFetchApi({
    url: `/analysis`,
    params: {
      _fields: postsFields,
      _embed: true,
      per_page: 100,
      page
    },
    method: "GET"
  });
  return response;
});
interface LatestPostArgs {
  withLatestPost?: boolean;
  page?: number;
  categoryId?: number | null;
  tagId?: number | null;
}
export const fetchLatestPost = createAsyncThunk<{
  data: CampusPostAPI[];
  totalPosts: number;
  categoryId: number | null;
  tagId: number | null;
  currentPage: number;
  totalPages: number;
}, LatestPostArgs>("analysis/fetchLatestPost", async ({
  withLatestPost = defaultArgs.withLatestPost,
  page = defaultArgs.page,
  categoryId = null,
  tagId = null
}) => {
  let perPage = defaultPerPage;

  if (withLatestPost && page === 1) {
    perPage = defaultPerPage + 1;
  }

  const getOffset = () => {
    if (!page || page === 1) {
      return 0;
    }

    if (withLatestPost) {
      if (page === 2) {
        return defaultPerPage + 1;
      }

      return (page - 1) * defaultPerPage + 1;
    }

    return (page - 1) * defaultPerPage;
  };

  const response = await blogFetchApi({
    url: "/analysis",
    params: {
      _fields: postsFields,
      _embed: true,
      per_page: perPage,
      offset: getOffset(),
      analysis_category: categoryId,
      analysis_tag: tagId
    },
    method: "GET"
  });

  const getTotalPages = () => {
    if (withLatestPost) {
      return Math.ceil((response.totalPosts - 1) / defaultPerPage) || 1;
    }

    return Math.ceil(response.totalPosts / defaultPerPage) || 1;
  };

  return {
    categoryId,
    tagId,
    currentPage: page || 1,
    ...response,
    totalPages: getTotalPages()
  };
});
export const fetchTags = createAsyncThunk<CampusCategoryAPI[]>("analysis/fetchTags", async () => {
  const fields = ["id", "description", "name", "slug", "type"].join(",");
  const response = await blogFetchApi({
    url: "/analysis_tag",
    params: {
      _fields: fields,
      per_page: 100
    },
    method: "GET"
  });
  return response.data;
});
export const fetchActivePost = createAsyncThunk<CampusActivePostAPI, string>("analysis/fetchActivePost", async slug => {
  const fields = ["id", "date_gmt", "slug", "title", "content", "_links", "_embedded", "estimated_reading_time", "type", "yoast_head", "old_slugs"];
  const response = await blogFetchApi<CampusActivePostAPI[]>({
    url: "/analysis",
    params: {
      slug,
      _fields: fields.join(","),
      _embed: true
    },
    method: "GET"
  });

  if (response.data.length === 0) {
    throw new Error("Post not found");
  }

  const activePost = { ...response.data[0],
    content: {
      rendered: await wrapTableWithFigure(response.data[0].content.rendered)
    }
  };
  return activePost;
});
export const fetchCategories = createAsyncThunk<CampusCategoryAPI[]>("analysis/fetchCategories", async () => {
  const fields = ["id", "description", "name", "slug", "taxonomy"].join(",");
  const response = await blogFetchApi({
    url: "/analysis_category",
    params: {
      _fields: fields,
      exclude: 1
    },
    method: "GET"
  });
  return response.data;
});
interface SearchPostsArgs {
  search: string;
  page?: number;
}
export const searchPosts = createAsyncThunk<{
  data: CampusPostAPI[];
  totalPages: number;
}, SearchPostsArgs>("analysis/searchPosts", async ({
  search,
  page = 1
}) => {
  const response = await blogFetchApi({
    url: "/analysis",
    params: {
      search,
      _fields: postsFields,
      _embed: true,
      per_page: 12,
      page
    },
    method: "GET"
  });
  return response;
});
export const fetchRelatedPosts = createAsyncThunk<{
  data: CampusPostAPI[];
  currentCategoryId: number;
}, number>("analysis/fetchRelatedPosts", async categoryId => {
  const fields = ["id", "date_gmt", "slug", "title", "excerpt", "_links", "_embedded", "estimated_reading_time", "type"];
  const response = await blogFetchApi({
    url: "/analysis",
    params: {
      _fields: fields.join(","),
      _embed: true,
      per_page: 3,
      analysis_category: categoryId
    },
    method: "GET"
  });
  return {
    data: response.data,
    currentCategoryId: categoryId
  };
});