import _styled from "@emotion/styled/base";
import { getAlign, getJustify } from "./util";
interface Props {
  /**
   * Align items
   */
  align?: "start" | "end" | "center" | "baseline";
  /**
   * Justify content
   */

  justify?: "start" | "end" | "center" | "between";
  /**
   * The space direction
   */

  direction?: "horizontal" | "vertical";
  /**
   * The space size
   */

  size?: "small" | "middle" | "large";
}

const Space = _styled("div", process.env.NODE_ENV === "production" ? {
  target: "eqd2fwx0"
} : {
  target: "eqd2fwx0",
  label: "Space"
})("display:inline-flex;width:auto;flex-direction:", ({
  direction
}) => direction === "vertical" ? "column" : "row", ";align-items:", ({
  align
}) => getAlign(align), ";justify-content:", ({
  justify
}) => getJustify(justify), ";gap:", ({
  theme,
  size
}) => `${theme.spacing[size || "small"]}px`, ";" + (process.env.NODE_ENV === "production" ? "" : "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2xheW91dHMvU3BhY2UvU3BhY2UudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQXVCK0IiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvbGF5b3V0cy9TcGFjZS9TcGFjZS50c3giLCJzb3VyY2VzQ29udGVudCI6WyJpbXBvcnQgc3R5bGVkIGZyb20gXCJAZW1vdGlvbi9zdHlsZWRcIlxuXG5pbXBvcnQgeyBnZXRBbGlnbiwgZ2V0SnVzdGlmeSB9IGZyb20gXCIuL3V0aWxcIlxuXG5pbnRlcmZhY2UgUHJvcHMge1xuICAgIC8qKlxuICAgICAqIEFsaWduIGl0ZW1zXG4gICAgICovXG4gICAgYWxpZ24/OiBcInN0YXJ0XCIgfCBcImVuZFwiIHwgXCJjZW50ZXJcIiB8IFwiYmFzZWxpbmVcIlxuICAgIC8qKlxuICAgICAqIEp1c3RpZnkgY29udGVudFxuICAgICAqL1xuICAgIGp1c3RpZnk/OiBcInN0YXJ0XCIgfCBcImVuZFwiIHwgXCJjZW50ZXJcIiB8IFwiYmV0d2VlblwiXG4gICAgLyoqXG4gICAgICogVGhlIHNwYWNlIGRpcmVjdGlvblxuICAgICAqL1xuICAgIGRpcmVjdGlvbj86IFwiaG9yaXpvbnRhbFwiIHwgXCJ2ZXJ0aWNhbFwiXG4gICAgLyoqXG4gICAgICogVGhlIHNwYWNlIHNpemVcbiAgICAgKi9cbiAgICBzaXplPzogXCJzbWFsbFwiIHwgXCJtaWRkbGVcIiB8IFwibGFyZ2VcIlxufVxuXG5jb25zdCBTcGFjZSA9IHN0eWxlZC5kaXY8UHJvcHM+YFxuICAgIGRpc3BsYXk6IGlubGluZS1mbGV4O1xuICAgIHdpZHRoOiBhdXRvO1xuICAgIGZsZXgtZGlyZWN0aW9uOiAkeyh7IGRpcmVjdGlvbiB9KSA9PiAoZGlyZWN0aW9uID09PSBcInZlcnRpY2FsXCIgPyBcImNvbHVtblwiIDogXCJyb3dcIil9O1xuICAgIGFsaWduLWl0ZW1zOiAkeyh7IGFsaWduIH0pID0+IGdldEFsaWduKGFsaWduKX07XG4gICAganVzdGlmeS1jb250ZW50OiAkeyh7IGp1c3RpZnkgfSkgPT4gZ2V0SnVzdGlmeShqdXN0aWZ5KX07XG4gICAgZ2FwOiAkeyh7IHRoZW1lLCBzaXplIH0pID0+IGAke3RoZW1lLnNwYWNpbmdbc2l6ZSB8fCBcInNtYWxsXCJdfXB4YH07XG5gXG5cblNwYWNlLmRlZmF1bHRQcm9wcyA9IHtcbiAgICBhbGlnbjogdW5kZWZpbmVkLFxuICAgIGRpcmVjdGlvbjogXCJob3Jpem9udGFsXCIsXG4gICAgc2l6ZTogXCJzbWFsbFwiXG59XG5cbmV4cG9ydCBkZWZhdWx0IFNwYWNlXG4iXX0= */"));

Space.defaultProps = {
  align: undefined,
  direction: "horizontal",
  size: "small"
};
export default Space;