/* eslint-disable import/prefer-default-export */
import fetchApi from "@api/baseApi";
import { getAuth } from "@helpers/auth";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const fetchPendingOrder = createAsyncThunk<PendingOrdersApi>("order/fetchPendingOrder", async () => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/pendingorderall",
    method: "POST",
    data: {
      token: auth.token
    }
  });
  return response;
});
export const cancelAllOrders = createAsyncThunk("order/cancelAllOrder", async () => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/cancelorder",
    method: "POST",
    data: {
      token: auth.token
    }
  });
  return response;
});
export const cancelOrder = createAsyncThunk("order/cancelOrder", async ({
  id
}: CancelOrderPayload) => {
  const auth = getAuth();
  const response = await fetchApi({
    url: "/cancelorder",
    method: "POST",
    data: {
      token: auth.token,
      id
    }
  });
  return response;
});