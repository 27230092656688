import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchKYCStatusAction } from "./actions";
const kycStatusSlice = createSlice({
  name: "kycStatus",
  initialState: {
    kycStatus: (null as KYCStatusModel | null),
    isLoading: false
  },
  reducers: {},
  extraReducers: {
    [fetchKYCStatusAction.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchKYCStatusAction.fulfilled.type]: (state, action: PayloadAction<KYCStatusAPI>) => {
      state.kycStatus = {
        isVerifiedKYC: action.payload.is_verified_kyc,
        isCompleteBappebtiCompliantData: action.payload.is_complete_bappebti_compliant_data
      };
      state.isLoading = false;
    }
  }
});
export default kycStatusSlice;