import baseFetchApi from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
interface DownlineArgs {
  limit: number;
  page: number;
}
export interface ReferralDownlineResponse extends DownlineArgs {
  total: number;
  members: ReferralDownlineAPI[];
}
export const fetchReferralDownline = createAsyncThunk<ReferralDownlineResponse, DownlineArgs>("referral/fetchReferralDownline", async ({
  limit,
  page
}) => {
  const response = await baseFetchApi<ReferralDownlineResponse>({
    url: "/downline-member",
    method: "GET",
    params: {
      limit,
      page
    }
  });
  return response;
});
export const fetchReferralDownlinePagination = createAsyncThunk<ReferralDownlineResponse, DownlineArgs>("referral/fetchReferralDownlinePagination", async ({
  limit,
  page
}) => {
  const response = await baseFetchApi<ReferralDownlineResponse>({
    url: "/downline-member",
    method: "GET",
    params: {
      limit,
      page
    }
  });
  return response;
});