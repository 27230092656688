import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchMissionPolling } from "./actions";
const missionPollingSlicer = createSlice({
  name: "missionPolling",
  initialState: {
    missionPolling: (null as MissionPollingModel | null),
    isLoading: false,
    isShow: true,
    timeoutId: ((null as unknown) as Date)
  },
  reducers: {
    hideMissionPolling: (state, action) => ({ ...state,
      isShow: action.payload
    }),
    setTimeoutId: (state, action) => ({ ...state,
      timeoutId: action.payload
    })
  },
  extraReducers: {
    [fetchMissionPolling.pending.type]: state => {
      state.isLoading = true;
    },
    [fetchMissionPolling.fulfilled.type]: (state, action: PayloadAction<MissionPollingAPI>) => {
      state.isLoading = false;
      state.missionPolling = {
        hasParticipateQuiz: action.payload.has_participate_quiz,
        highestRewardAmount: action.payload.highest_reward_amount
      };
    },
    [fetchMissionPolling.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export const {
  hideMissionPolling,
  setTimeoutId
} = missionPollingSlicer.actions;
export default missionPollingSlicer;