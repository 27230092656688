import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchSummaryBarChart } from "./actions";
const portfolioSummaryBarSlice = createSlice({
  name: "barChart",
  initialState: {
    barChart: (null as PortfolioSummaryBarModel | null),
    isLoading: true
  },
  reducers: {},
  extraReducers: {
    [fetchSummaryBarChart.pending.type]: state => {
      state.barChart = null;
      state.isLoading = true;
    },
    [fetchSummaryBarChart.fulfilled.type]: (state, action: PayloadAction<PortfolioSummaryBarAPI>) => {
      state.barChart = action.payload.map(chart => ({
        timestamp: chart.t,
        percentage: chart.p,
        amount: chart.a,
        startAmount: chart.sa,
        endAmount: chart.ea,
        startPercentage: chart.sp,
        endPercentage: chart.ep
      }));
      state.isLoading = false;
    },
    [fetchSummaryBarChart.rejected.type]: state => {
      state.isLoading = false;
    }
  }
});
export default portfolioSummaryBarSlice;