import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const checkResetPasswordHash = createAsyncThunk<CheckResetPasswordHashAPI, string>("resetPassword/checkResetPasswordHash", async (hash, {
  rejectWithValue
}) => {
  try {
    const response = baseFetchApiV3<BaseResponseAPIV3<CheckResetPasswordHashAPI>>({
      url: `/resetpassword/${hash}`
    });
    return (await response).result;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});
export interface ResetPasswordArgs {
  hash: string;
  password: string;
  otp?: string;
}
export const resetPassword = createAsyncThunk<SuccessV2API, ResetPasswordArgs>("resetPassword/resetPassword", async ({
  hash,
  password,
  otp
}) => {
  const response = await baseFetchApiV3<SuccessV2API>({
    url: "/resetnow",
    method: "POST",
    data: {
      hash,
      password,
      otp
    }
  });
  return response;
});