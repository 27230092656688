import { baseFetchApiV3 } from "@api/baseApi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { formatAddress } from "@utils/address";
export const feeSendCoin = createAsyncThunk<FeeSendCoinAPI, FeeSendCoinPayload>("sendCoin/feeSendCoin", async ({
  address,
  chainId,
  coinCode,
  tagAddress,
  tagUrl
}) => {
  const response = await baseFetchApiV3<FeeSendCoinAPI>({
    url: `wallet/crypto/${coinCode}/withdraw/${chainId}/fee`,
    method: "POST",
    data: {
      address: formatAddress({
        address,
        tagAddress,
        tagUrl
      })
    }
  });
  return response;
});