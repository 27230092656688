import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

const BaseIndicator = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "ecjlocz0"
} : {
  target: "ecjlocz0",
  label: "BaseIndicator"
})(process.env.NODE_ENV === "production" ? {
  name: "j22hal",
  styles: "border-radius:var(--borderRadius-sm);border-width:1px;border-style:solid;--tw-border-opacity:1;border-color:rgba(var(--colors-background), var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(var(--colors-icon-error), var(--tw-bg-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-color:rgba(var(--colors-dark-background), var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(var(--colors-dark-icon-error), var(--tw-bg-opacity));}"
} : {
  name: "j22hal",
  styles: "border-radius:var(--borderRadius-sm);border-width:1px;border-style:solid;--tw-border-opacity:1;border-color:rgba(var(--colors-background), var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(var(--colors-icon-error), var(--tw-bg-opacity));:is([data-theme=\"dark\"] &){--tw-border-opacity:1;border-color:rgba(var(--colors-dark-background), var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(var(--colors-dark-icon-error), var(--tw-bg-opacity));}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvSW5kaWNhdG9yL0Jhc2VJbmRpY2F0b3IudHN4Il0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVzQiIsImZpbGUiOiIvdmVyY2VsL3BhdGgwL3NyYy9jb21wb25lbnRzL0luZGljYXRvci9CYXNlSW5kaWNhdG9yLnRzeCIsInNvdXJjZXNDb250ZW50IjpbImltcG9ydCB0dyBmcm9tIFwidHdpbi5tYWNyb1wiXG5cbmNvbnN0IEJhc2VJbmRpY2F0b3IgPSB0dy5kaXZgXG4gICAgXG4gICAgYmctaWNvbi1lcnJvciBkYXJrOmJnLWRhcmstaWNvbi1lcnJvclxuICAgIHJvdW5kZWQtc21cbiAgICBib3JkZXIgYm9yZGVyLXNvbGlkIGJvcmRlci1iYWNrZ3JvdW5kIGRhcms6Ym9yZGVyLWRhcmstYmFja2dyb3VuZFxuYFxuXG5leHBvcnQgZGVmYXVsdCBCYXNlSW5kaWNhdG9yXG4iXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

export default BaseIndicator;