/* eslint-disable import/prefer-default-export */
import { FIREBASE_API_KEY, FIREBASE_DATABASE_URL } from "./config";
export const firebaseConfig = {
  apiKey: FIREBASE_API_KEY,
  authDomain: "api-7805420894961338446-4816.firebaseapp.com",
  databaseURL: "https://api-7805420894961338446-4816.firebaseio.com",
  projectId: "api-7805420894961338446-4816",
  storageBucket: "api-7805420894961338446-4816.appspot.com",
  messagingSenderId: "803093385850",
  appId: "1:803093385850:web:0439726a7e76ed51a061c3",
  measurementId: "G-7121JQS6NY"
};

const initializeFirebaseApp = async () => {
  const {
    initializeApp
  } = await import("firebase/app");
  const firebaseDatabaseConfig = { ...firebaseConfig,
    databaseURL: FIREBASE_DATABASE_URL
  };
  return initializeApp(firebaseDatabaseConfig, "FIREBASE_DATABASE");
};

export const getFirebaseDatabase = async () => {
  const {
    getDatabase
  } = await import("firebase/database");
  return getDatabase(await initializeFirebaseApp());
};