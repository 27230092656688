import { createSlice } from "@reduxjs/toolkit";
const globalLoadingSlice = createSlice({
  name: "globalLoading",
  initialState: {
    isGlobalLoading: false
  },
  reducers: {
    startGlobalLoading: state => {
      state.isGlobalLoading = true;
    },
    stopGlobalLoading: state => {
      state.isGlobalLoading = false;
    }
  }
});
export const {
  startGlobalLoading,
  stopGlobalLoading
} = globalLoadingSlice.actions;
export default globalLoadingSlice;