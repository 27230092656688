import { HYDRATE } from "next-redux-wrapper";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserMode } from "@type/api/user-mode";
import { fetchMarkets, fetchMarketsAsync, FetchMarketsResponse } from "./actions";
const marketsSlicer = createSlice({
  name: "markets",
  initialState: {
    mode: (null as UserMode | null),
    enable: false,
    delisted: false,
    markets: (null as MarketModel[] | null),
    isLoading: false
  },
  reducers: {
    updateMarkets: (state, action: PayloadAction<MarketSocketPayload>) => {
      if (!state.markets) {
        return;
      }

      const data = action.payload;
      const index = state.markets.findIndex(e => e.code === data.c);

      if (!state.markets[index]) {
        return;
      }

      state.markets[index] = { ...state.markets[index],
        price: {
          open: data.o,
          high: data.h,
          low: data.l,
          close: data.cl,
          mid: data.mp,
          buy: data.bp,
          sell: data.sp,
          volume: data.v,
          changePercentage: {
            default: data.cp,
            "24hour": data.cp24h,
            "1week": data.cp1w,
            "1month": data.cp1m,
            "1year": data.cp1y
          }
        }
      };
    }
  },
  extraReducers: {
    [fetchMarkets.pending.type]: (state, action) => {
      state.isLoading = true;
      state.markets = null;
      state.mode = action.meta.arg.mode;
      state.enable = action.meta.arg.enable || false;
      state.delisted = action.meta.arg.delisted || false;
    },
    [fetchMarkets.fulfilled.type]: (state, action: PayloadAction<FetchMarketsResponse>) => {
      if (state.mode !== action.payload.mode) {
        return;
      }

      state.markets = action.payload.markets.map(market => ({
        id: market.id,
        name: market.n,
        code: market.cd,
        logo: market.logo,
        otcId: market.otc_id,
        sorting: market.sorting,
        isLite: market.is_lite,
        isPro: market.is_pro,
        isNew: market.is_new,
        isStakeble: market.is_stakable,
        price: {
          open: market.o,
          high: market.h,
          low: market.l,
          close: market.c,
          mid: market.mp,
          buy: market.bp,
          sell: market.sp,
          volume: market.v,
          changePercentage: {
            default: market.cp,
            "24hour": market.cp24h,
            "1week": market.cp1w,
            "1month": market.cp1m,
            "1year": market.cp1y
          }
        },
        information: {
          maxSupply: market.ms,
          circulatingSupply: market.cs,
          totalSupply: market.ts,
          marketCap: market.c * market.cs,
          globalVolume: market.gv
        },
        digits: market.digits,
        decimals: {
          price: market.price_decimals,
          volume: market.volume_decimals
        },
        buyPrice: market.bp,
        sellPrice: market.sp,
        midPrice: market.mp
      }));
      state.isLoading = false;
    },
    [fetchMarketsAsync.fulfilled.type]: (state, action: PayloadAction<FetchMarketsResponse>) => {
      state.markets = action.payload.markets.map(market => ({
        id: market.id,
        name: market.n,
        code: market.cd,
        logo: market.logo,
        otcId: market.otc_id,
        sorting: market.sorting,
        isLite: market.is_lite,
        isPro: market.is_pro,
        isNew: market.is_new,
        isStakeble: market.is_stakable,
        price: {
          open: market.o,
          high: market.h,
          low: market.l,
          close: market.c,
          mid: market.mp,
          buy: market.bp,
          sell: market.sp,
          volume: market.v,
          changePercentage: {
            default: market.cp,
            "24hour": market.cp24h,
            "1week": market.cp1w,
            "1month": market.cp1m,
            "1year": market.cp1y
          }
        },
        information: {
          maxSupply: market.ms,
          circulatingSupply: market.cs,
          totalSupply: market.ts,
          marketCap: market.c * market.cs,
          globalVolume: market.gv
        },
        digits: market.digits,
        decimals: {
          price: market.price_decimals,
          volume: market.volume_decimals
        },
        buyPrice: market.bp,
        sellPrice: market.sp,
        midPrice: market.mp
      }));
      state.isLoading = false;
    },
    [HYDRATE]: (state, action) => {
      if (action.payload?.markets?.markets) {
        state.mode = action.payload.markets.mode;
        state.markets = action.payload.markets.markets;
      }
    }
  }
});
export const {
  updateMarkets
} = marketsSlicer.actions;
export default marketsSlicer;